import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";

import style from "./index.module.css";

import GetImages from "../GetImages";

import {
  IDoctorConsultationSelection,
  IDoctorConsultationType,
} from "../../models/doctor";
import { IScheduleAvailabilitySetting } from "../../models/ApiRequestResponse/doctor";

import { convertDaysToRanges, timeInAmPmFormat } from "../../utils";
import useIsMobile from "../../utils/hooks/useIsMobile";

import { useAppDispatch } from "../../store/hooks";
import { detail } from "../../store/slices/doctor/availabilitySettings";

const AvailabilityListCard: React.FC<IScheduleAvailabilitySetting> = (
  props
) => {
  const {
    id,
    clinic_name,
    consultation_fee,
    start_time,
    end_time,
    slot_duration,
    slots,
    type_of_consultation,
    days,
  } = props;
  const { isMobile } = useIsMobile();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const handleSpecificAvailabilitySetting = () => {
    dispatch(detail(props));
    if (isMobile) {
      navigate("/availability-create");
    }
  };

  const getAvailabilityDaysRange = useCallback(() => {
    return convertDaysToRanges(days);
  }, [days]);

  return (
    <Grid
      item
      key={id}
      className={style.container}
      onClick={handleSpecificAvailabilitySetting}
    >
      <Grid item className={style.availabilityCommon}>
        <Grid item className={style.titleIconContainer}>
          <GetImages
            name={
              type_of_consultation === IDoctorConsultationType.IN_CLINIC
                ? "ClinicIcon"
                : "VideoIcon"
            }
            width="20"
            height="20"
          />
          <Typography component={"h5"} className={style.headerText}>
            {type_of_consultation === IDoctorConsultationType.IN_CLINIC
              ? clinic_name
              : IDoctorConsultationSelection.VIDEO_CONSULTATION}
          </Typography>
        </Grid>
        <Grid item>
          <Typography component={"p"} className={style.headerText}>
            {getAvailabilityDaysRange()}
          </Typography>
        </Grid>
      </Grid>
      <Grid item className={style.availabilityCommon}>
        <Typography component={"p"} className={style.footerText}>
          ₹{consultation_fee} / {slot_duration}min
        </Typography>
        <Typography component={"p"} className={style.footerText}>
          {slots} slots • {timeInAmPmFormat(start_time)} to{" "}
          {timeInAmPmFormat(end_time)}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AvailabilityListCard;
