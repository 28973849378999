import React, { useEffect, useState } from "react";
import { BottomNavigation as Navigation } from "@mui/material";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { Grid } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import style from "./index.module.css";

import GetImages from "../../../GetImages";

import { IRoleType } from "../../../../models/role";

import { storage } from "../../../../utils/Storage";

import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { resetDoctorSearch } from "../../../../store/slices/patient/findDoctor";
import { useIntl } from "react-intl";

interface INavItemInfo {
  path: string;
  icon: string;
  selectedIcon: string;
  label: string;
}
const navigation = {
  [IRoleType.PATIENT]: [
    {
      path: "/home",
      icon: "HomeIcon",
      selectedIcon: "HomeSelectedIcon",
      label: "home",
    },
    {
      path: "/find-doctor",
      icon: "DoctorIcon",
      selectedIcon: "DoctorSelectedIcon",
      label: "doctors",
    },
    {
      path: "/medicines",
      icon: "MedicineIcon",
      selectedIcon: "MedicineSelectedIcon",
      label: "medicines",
    },
    {
      path: "/test-reports",
      icon: "TestIcon",
      selectedIcon: "TestSelectedIcon",
      label: "tests",
    },
    {
      path: "/health-tips",
      icon: "TvIcon",
      selectedIcon: "TvSelectedIcon",
      label: "health",
    },
  ],
  [IRoleType.DOCTOR]: [
    {
      path: "/schedule",
      icon: "ScheduleIcon",
      selectedIcon: "ScheduleSelectedIcon",
      label: "schedule",
    },
    {
      path: "/ip-dash",
      icon: "IpDashIcon",
      selectedIcon: "IpDashSelectedIcon",
      label: "ip_dash",
    },
    {
      path: "/chats",
      icon: "ChatIcon",
      selectedIcon: "ChatSelectedIcon",
      label: "chat",
    },
    {
      path: "/profile",
      icon: "ProfileIcon",
      selectedIcon: "ProfileSelectedIcon",
      label: "profile",
    },
  ],
};

const BottomNavigation: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const intl = useIntl();

  const [navIndex, setNavIndex] = useState<number>(
    location.state?.navItem ?? 0
  );
  const [navItems, setNavItems] = useState<INavItemInfo[]>();

  const dispatch = useAppDispatch();
  const findDoctorSearch = useAppSelector(
    (state) => state.findDoctor.isDoctorSearch
  );

  const roleType = storage.getSessionToken("roleType");

  const navigateToPage = () => {
    if (roleType === IRoleType.PATIENT || roleType === IRoleType.DOCTOR) {
      navigate(navigation[roleType][navIndex]?.path);
    }
  };

  const setNavigationItemsByRoleType = () => {
    if (roleType === IRoleType.PATIENT || roleType === IRoleType.DOCTOR) {
      setNavItems(navigation[roleType]);
    }
  };

  useEffect(() => {
    if (findDoctorSearch) {
      dispatch(resetDoctorSearch());
    }
    navigateToPage();
  }, [navIndex]);

  useEffect(() => {
    if (location.state) {
      setNavIndex(location?.state?.navItem ?? 0);
    }
  }, [location.state]);

  useEffect(() => {
    setNavigationItemsByRoleType();
  }, []);
  return (
    <Grid item className={style.navigationContainer}>
      <Navigation
        showLabels
        value={navIndex}
        sx={{ padding: "0.5rem 0rem" }}
        onChange={(event, newValue) => setNavIndex(newValue)}
      >
        {navItems &&
          navItems.map((nav, index) => (
            <BottomNavigationAction
              label={intl.formatMessage({
                id: nav.label,
                defaultMessage: "Bottom Navigation Action",
              })}
              icon={
                navIndex === index ? (
                  <GetImages name={nav.selectedIcon} width="24" height="24" />
                ) : (
                  <GetImages name={nav.icon} width="24" height="24" />
                )
              }
              key={index}
              sx={{
                color: "var(--gray-400)",
                fontSize: "1.2rem",
                lineHeight: "1.6rem",
                fontWeight: "600",
                "&.Mui-selected": {
                  color: "var(--orange-500)",
                  fontSize: "1.2rem",
                  fontWeight: "600",
                },
              }}
            />
          ))}
      </Navigation>
    </Grid>
  );
};

export default BottomNavigation;
