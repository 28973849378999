import React from "react";
import AliceCarousel from "react-alice-carousel";

import css from "./index.module.css";
import { Grid } from "@mui/material";

interface IProps {
  items: React.ReactNode | any;
  isDotsShow?: boolean;
  itemPerPage?: number;
  autoWidth?: boolean;
  autoPlay?: boolean;
  infinite?:boolean;
}

const Carousel: React.FC<IProps> = (props) => {
  const {
    items,
    isDotsShow = true,
    itemPerPage = 1,
    autoWidth,
    autoPlay,
    infinite,
  } = props;

  const responsive = {
    0: { items: itemPerPage },
    568: { items: itemPerPage },
    1024: { items: itemPerPage },
  };

  return (
    <Grid item className={css.aliceCarouselGap}>
      <AliceCarousel
        touchTracking
        mouseTracking
        items={items}
        responsive={responsive}
        disableDotsControls={!isDotsShow}
        disableButtonsControls
        autoWidth={autoWidth}
        autoPlay={autoPlay}
        autoPlayInterval={3000}
        animationDuration={1000}
        infinite={infinite}
      />
    </Grid>
  );
};

export default Carousel;
