import React, { useEffect } from "react";
import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import style from "./index.module.css";

import { IChipVariant } from "../../models/button";
import { IKeyValueChip } from "../../models";

import { transformClasses } from "../../utils";

import Carousel from "../Carousel";
import GetImages from "../GetImages";

interface IProps {
  data: IKeyValueChip[];
  variant?: IChipVariant;
  showCarousel?: boolean;
  showClearBtn?: boolean;
  selectedValue: string;
  setSelectedValue: (value: string, identifier?: string) => void;
  clear?: () => void;
  identifier?: string;
}
const ChipSelect: React.FC<IProps> = (props) => {
  const {
    data,
    variant = "",
    showCarousel = false,
    showClearBtn = false,
    selectedValue,
    setSelectedValue,
    clear,
    identifier,
  } = props;

  const handleClearBtn = () => {
    clear && clear();
    setSelectedValue(data[0].value);
  };

  const clearBtn = () => {
    return (
      <>
        {selectedValue !== data[0].value && (
          <Grid item className={style.clearBtn} onClick={handleClearBtn}>
            <CloseIcon
              sx={{ width: 16, height: 16, color: "var(--neutral-100)" }}
            />
            <Typography component={"p"} className={style.clearText}>
              Clear
            </Typography>
          </Grid>
        )}
      </>
    );
  };

  const handleSelection = (event: React.ChangeEvent<any>) => {
    setSelectedValue(event.target.value, identifier!);
  };

  const chipItems =
    data?.length > 0
      ? data.map((info, index) => (
          <Grid
            item
            className={style.chipItemContainer}
            key={`${info.value}-${index}`}
          >
            <FormControlLabel
              key={`${info.label}-${index}`}
              value={info.value}
              className={transformClasses(
                variant === IChipVariant.ROUNDED ? style.roundChip : "",
                info.isBooked ? transformClasses(style.notAvailable) : "",
                info.value === selectedValue
                  ? style.selected
                  : style.selectionDefault
              )}
              control={
                <Radio className={style.radioBtn} disabled={info.isBooked} />
              }
              onClick={handleSelection}
              label={
                <>
                  {info.label}{" "}
                  {info.isSelection && (
                    <>
                      {" "}
                      {selectedValue === info.value ? (
                        <GetImages
                          name="DownArrowWhiteIcon"
                          width="10"
                          height="6"
                        />
                      ) : selectedValue !== info.value ? (
                        <GetImages name="DownArrowIcon" width="10" height="6" />
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </>
              }
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: "1.2rem",
                },
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            />
          </Grid>
        ))
      : [];

  useEffect(() => {
    if (data && data[0]?.value) {
      setSelectedValue(data[0].value);
    }
  }, []);
  return (
    <RadioGroup row className={style.chipContainer}>
      {showCarousel ? (
        <Carousel
          items={[...chipItems, showClearBtn ? clearBtn() : []]}
          itemPerPage={4}
          isDotsShow={false}
          autoWidth
        />
      ) : showClearBtn ? (
        [...chipItems, clearBtn()]
      ) : (
        chipItems
      )}
    </RadioGroup>
  );
};

export default ChipSelect;
