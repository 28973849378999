import {
  IAddDeliveryAddressDetailReqRes,
  IAddDeliveryAddressResponse,
  IMedicalRecordsByMemberIdResponse,
  IMedicalRecordsPatientResponse,
  IMedicineOrderPlacedResponse,
  IMedicinePlaceOrderRequest,
  IPatientDeliveryAddressResponse,
  IPharmacyClinicResponse,
} from "../../../models/ApiRequestResponse/patient";
import { GBSCError } from "../../../models/GBSCError";

import { get, post } from "../../TypedApi";

import {
  APIEndpoint,
  IAPIStatusCode,
  IAPIStatusType,
  messages,
} from "../APIEndpointConfig";

export const getPharmacyClinicsListApi = async (
  latitude: number,
  longitude: number,
  search?: string
): Promise<IPharmacyClinicResponse | GBSCError> => {
  try {
    let url = `${APIEndpoint.patient.pharmacyList}&latitude=${latitude}&longitude=${longitude}`;
    if (search) {
      url += `&search=${search}`;
    }
    const response = await get<IPharmacyClinicResponse>(url);
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const addPatientDeliveryAddressApi = async (
  requestBody: IAddDeliveryAddressDetailReqRes
): Promise<IAddDeliveryAddressResponse | GBSCError> => {
  try {
    const response = await post<IAddDeliveryAddressResponse>(
      APIEndpoint.patient.patientAddress,
      requestBody
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const getPatientDeliveryAddressApi = async (): Promise<
  IPatientDeliveryAddressResponse | GBSCError
> => {
  try {
    const response = await get<IPatientDeliveryAddressResponse>(
      APIEndpoint.patient.patientAddress
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const getPatientMedicalRecordsApi = async (): Promise<
  IMedicalRecordsPatientResponse | GBSCError
> => {
  try {
    const response = await get<IMedicalRecordsPatientResponse>(
      APIEndpoint.patient.patientMedicalRecords
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const getPatientMedicalRecordsByMemberIdApi = async (
  memberId: string
): Promise<IMedicalRecordsByMemberIdResponse | GBSCError> => {
  try {
    const response = await get<IMedicalRecordsByMemberIdResponse>(
      `${APIEndpoint.patient.patientMedicalRecordsByMemberId}${memberId}`
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const medicineOrderPlacedApi = async (
  requestBody: IMedicinePlaceOrderRequest
): Promise<IMedicineOrderPlacedResponse | GBSCError> => {
  try {
    const response = await post<IMedicineOrderPlacedResponse>(
      APIEndpoint.patient.medicineOrderPlaced,
      requestBody
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};
