import React, { Suspense, useEffect, useRef, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { onMessage } from "firebase/messaging";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import "react-toastify/dist/ReactToastify.css";

import Router from "./routes/Router";

import "./App.css";
import "./colors.css";

import Loader from "./components/Loader";
import Incoming from "./components/VideoCall/CallingScreen/Incoming";

import { IAPIStatusCode } from "./utils/apis/APIEndpointConfig";
import { getAccessTokenApi } from "./utils/apis/onboarding/OnBoardingApis";
import { storage } from "./utils/Storage";
import useIsMobile from "./utils/hooks/useIsMobile";

import { generateTokenFCM, messaging } from "./firebaseConfig";

import { useAppDispatch, useAppSelector } from "./store/hooks";
import {
  setCallerDetails,
  setFCMDeviceToken,
} from "./store/slices/videoAppointment";
import { LanguageProvider } from "./i18n";

const theme = createTheme({
  typography: {
    htmlFontSize: 10,
  },
});

const App: React.FC = () => {
  const [loading, setLoading] = useState(true);

  const dispatch = useAppDispatch();
  const { callerDetails } = useAppSelector((state) => state.videoAppointment);

  const navigate = useNavigate();
  const { isMobile } = useIsMobile();
  const isMobileRef = useRef(isMobile);

  const getAccessTokenDetail = async () => {
    const getAccessTokenRes = await getAccessTokenApi();
    if (
      getAccessTokenRes.statusCode === IAPIStatusCode.SUCCESS &&
      "result" in getAccessTokenRes
    ) {
      // storage.setSessionToken("lang", getAccessTokenRes?.result?.language);
      storage.setSessionToken("user_id", getAccessTokenRes?.result?.user_id);
      storage.setSessionToken("roleType", getAccessTokenRes?.result?.role);
    }
  };

  const interceptCalls401 = () => {
    return axios.interceptors.response.use(
      (response) => {
        if (response.status >= 200 && response.status < 300) {
          setLoading(false);
        }
        return response;
      },
      (error) => {
        if (error.response) {
          const { status } = error.response;
          if (status === IAPIStatusCode.UNAUTHENTICATED) {
            if (isMobileRef.current) {
              navigate("/");
            } else {
              navigate("/home");
            }
            setLoading(false);
          }
        }
        return Promise.reject(error);
      }
    );
  };
  useEffect(() => {
    isMobileRef.current = isMobile;
  }, [isMobile]);

  useEffect(() => {
    const interceptorId = interceptCalls401();
    return () => {
      axios.interceptors.response.eject(interceptorId);
    };
  }, []);

  useEffect(() => {
    (async () => {
      const deviceToken = await generateTokenFCM();
      console.log("deviceToken", deviceToken);
      dispatch(setFCMDeviceToken(deviceToken));
    })();
  }, []);

  useEffect(() => {
    getAccessTokenDetail();
    onMessage(messaging, (payload) => {
      dispatch(setCallerDetails(payload?.data));
      console.log("Foreground message received:", payload);
    });

    navigator.serviceWorker
      .register("/firebase-messaging-sw.js") // Path relative to root
      .then((registration) => {
        console.log("Service Worker registered:", registration);
      })
      .catch((error) => {
        console.error("Service Worker registration failed:", error);
      });
  }, []);

  if (loading) {
    return null;
  }

  if (callerDetails) {
    return <Incoming />;
  }

  return (
    <Suspense fallback={<Loader />}>
      <ThemeProvider theme={theme}>
        <LanguageProvider>
          <ToastContainer />
          <Router />
        </LanguageProvider>
      </ThemeProvider>
    </Suspense>
  );
};

export default App;
