import { transformClasses } from "..";

import GetImages from "../../components/GetImages";

import {
  IMedicalRecordTypeEnum,
  IPatientDetailEnumWeb,
} from "../../models/doctor";

import style from "./index.module.css";

import ChatIcon from "../../assets/chat.png";
import Team1 from "../../assets/team-1.png";
import Team2 from "../../assets/team-2.png";
import Team3 from "../../assets/team-3.png";

import { useAppDispatch } from "../../store/hooks";
import { showHideAddMember } from "../../store/slices/videoAppointment";
import { setIsVideoCallChatInitiated ,setIsVideoMinimizedOrPrescInitiated} from "../../store/slices/videoAppointment";

import { IOrderTypeEnums } from "../../models/ApiRequestResponse/patient";

export const getStartedTutorial = [
  {
    image: <GetImages name="StayConnectedImage" width="304" height="245" />,
    title: "stayConnectedTitle",
    description: "stayConnectedDescription",
  },
  {
    image: <GetImages name="TestOnlineImage" width="268" height="279" />,
    title: "bookTestTitle",
    description: "bookTestDescription",
  },
  {
    image: <GetImages name="BookDoctorImage" width="310" height="199" />,
    title: "bookDoctorTitle",
    description: "bookDoctorDescription",
  },
];

export const locationAccessDetail = [
  {
    icon: <GetImages name="DoctorSearchIcon" width="20" height="20" />,
    title: "doctors",
    subHeding: "nearbyText",
  },
  {
    icon: <GetImages name="ClinicIcon" width="20" height="20" />,
    title: "clinicsText",
    subHeding: "nearbyText",
  },
  {
    icon: <GetImages name="LocationAccessIcon" width="36" height="36" />,
    title: "directionsText",
    subHeding: "toThemText",
  },
];

export const appointmentActionList = [
  {
    identifier: IPatientDetailEnumWeb.CANCEL,
    label: "Cancel",
    icon: "CancelIcon",
    labelStyle: style.actionText,
    backStyle: style.actionEventTriggerContainer,
  },
  {
    identifier: IPatientDetailEnumWeb.RESCHEDULE,
    label: "Reschedule",
    icon: "RescheduleIcon",
    labelStyle: style.actionText,
    backStyle: style.actionEventTriggerContainer,
  },
  {
    identifier: IPatientDetailEnumWeb.CHAT,
    label: "Chat",
    icon: "ChatIcon",
    labelStyle: style.actionText,
    backStyle: style.actionEventTriggerContainer,
  },
  {
    identifier: IPatientDetailEnumWeb.RECORD,
    label: "Record",
    icon: "RecordWebIcon",
    labelStyle: style.actionText,
    backStyle: style.actionEventTriggerContainer,
  },
  {
    identifier: IPatientDetailEnumWeb.PRESCRIPTION,
    label: "Prescription",
    icon: "PrescriptionWebIcon",
    labelStyle: style.actionText,
    backStyle: style.actionEventTriggerContainer,
  },
  {
    identifier: IPatientDetailEnumWeb.VIDEO_JOIN,
    label: "Join",
    icon: "VideoWhiteIcon",
    labelStyle: transformClasses(style.actionTextWhite, style.actionText),
    backStyle: transformClasses(
      style.joinVideoCall,
      style.actionEventTriggerContainer
    ),
  },
];

export const ipDashboardActionList = [
  {
    identifier: IPatientDetailEnumWeb.CHAT,
    label: "Chat",
    icon: "ChatIcon",
    labelStyle: style.actionText,
    backStyle: style.actionEventTriggerContainer,
  },
  {
    identifier: IPatientDetailEnumWeb.IP_DETAIL,
    label: "Details",
    icon: "SettingGreyedIcon",
    labelStyle: style.actionText,
    backStyle: style.actionEventTriggerContainer,
  },
  {
    identifier: IPatientDetailEnumWeb.RECORD,
    label: "Record",
    icon: "RecordWebIcon",
    labelStyle: style.actionText,
    backStyle: style.actionEventTriggerContainer,
  },
];

export const clinicTeamList = [
  {
    userId: "USR-1",
    name: "Chat",
    role: "JR_DOCTOR",
    profilePhoto: ChatIcon,
    phoneNumber: "9999955555",
    countryCode: "+91",
    age: 10,
    gender: "Male",
  },
  {
    userId: "USR-2",
    name: "Doctor",
    role: "JR_DOCTOR",
    profilePhoto: Team1,
    phoneNumber: "9999955555",
    countryCode: "+91",
    age: 10,
    gender: "Male",
  },
  {
    userId: "USR-3",
    name: "Jr Doctor",
    role: "JR_DOCTOR",
    profilePhoto: Team2,
    phoneNumber: "9999955555",
    countryCode: "+91",
    age: 10,
    gender: "Male",
  },
  {
    userId: "USR-4",
    name: "Co-or...",
    role: "JR_DOCTOR",
    profilePhoto: Team3,
    phoneNumber: "9999955555",
    countryCode: "+91",
    age: 10,
    gender: "Male",
  },
];

export const videoToolbarTop = () => {
  const dispatch = useAppDispatch();
  const videoToolbarTopList = [
    {
      icon: "VideoChatIcon",
      css: style.btnLayer,
      handler: () => {
        dispatch(setIsVideoCallChatInitiated({value:true}));
        dispatch(setIsVideoMinimizedOrPrescInitiated({value:true}));},
    },
    {
      icon: "AddMemberIcon",
      css: transformClasses(style.btnLayer, style.spacing),
      handler: () => dispatch(showHideAddMember()),
    },
    {
      icon: "CameraSwitchIcon",
      css: style.btnLayer,
      handler: () => {},
    },
  ];
  return { videoToolbarTopList };
};

export const socialLinks = [
  {
    iconName: "InstagramIcon",
    link: "#",
  },
  {
    iconName: "FBIcon",
    link: "#",
  },
  {
    iconName: "YoutubeIcon",
    link: "#",
  },
  {
    iconName: "LinkedInIcon",
    link: "#",
  },
];

export const contactBtns = [
  { btnText: "Call Us", iconName: "TelePhoneIcon", link: "#" },
  { btnText: "FAQ", iconName: "BookPlusIcon", link: "/faq" },
];

export const homeTitleDetail = [
  {
    icon: <GetImages name="DoctorSearchIcon" width="20" height="20" />,
    title: "doctors",
    subHeding: "available",
  },
  {
    icon: <GetImages name="MedicineSmallIcon" width="20" height="20" />,
    title: "medicines",
    subHeding: "free_delivery",
  },
  {
    icon: <GetImages name="TestSmallIcon" width="20" height="20" />,
    title: "tests",
    subHeding: "flat_off",
  },
];

export const medicalRecordFilterList = [
  {
    label: "All",
    value: IMedicalRecordTypeEnum.All,
  },
  {
    label: "Prescriptions",
    value: IMedicalRecordTypeEnum.PRESCRIPTION,
  },
  {
    label: "Reports",
    value: IMedicalRecordTypeEnum.REPORT,
  },
];

export const orderFilterList = [
  {
    label: "All",
    value: IOrderTypeEnums.ALL,
  },
  {
    label: "Clinic Visit",
    value: IOrderTypeEnums.IN_CLINIC,
  },
  {
    label: "Video Call",
    value: IOrderTypeEnums.VIDEO_CONSULTATION,
  },
  {
    label: "Medicine",
    value: IOrderTypeEnums.MEDICINE,
  },
  {
    label: "Tests",
    value: IOrderTypeEnums.TESTS,
  },
];

export const faqAllTag = [
  {
    id: "all",
    tag: "All",
  },
];

export const allFilterList = [
  {
    label: "All",
    value: "all",
  },
];
