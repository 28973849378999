import { createSlice } from "@reduxjs/toolkit";
import { IChangeHospitalModuleName } from "../../../models/doctor";

const initialState = {
  isProfileOpen: false as boolean,
  isReviewOpen: false as boolean,
  isAvailabilityOpen: false as boolean,
  isClinicTeamOpen: false as boolean,
  isTemplatesOpen: false as boolean,
  isChangeHospitalOpen: false as boolean,
};
const addPatientInitialState = {
  appointmentCreateOpen: false as boolean,
  mobileCheck: false as boolean,
  otpVerify: false as boolean,
  admiPatient: false as boolean,
};
const topNavigationSlice = createSlice({
  name: "topNavDoctor",
  initialState: {
    doctorProfile: initialState,
    addPatient: addPatientInitialState,
    isLocationAccessOpen: false as boolean,
    changeHospitalModuleName: "" as IChangeHospitalModuleName,
    isUniversalSearch: false as boolean,
  },
  reducers: {
    setDoctorProfileDropdown: (state: any, action) => {
      state.doctorProfile = {
        ...state.doctorProfile,
        [action.payload]: !state.doctorProfile[action.payload],
      };
    },
    setAddPatient: (state: any, action) => {
      state.addPatient = {
        ...state.addPatient,
        [action.payload.field]: action.payload.value,
      };
    },
    setChangeHospitalModuleName: (state, action) => {
      state.changeHospitalModuleName = action.payload;
    },
    setIsLocationAccess: (state, action) => {
      state.isLocationAccessOpen = action.payload;
    },
    setUniversalSearch: (state, action) => {
      state.isUniversalSearch = action.payload;
    },
  },
});

export const {
  setUniversalSearch,
  setIsLocationAccess,
  setDoctorProfileDropdown,
  setAddPatient,
  setChangeHospitalModuleName,
} = topNavigationSlice.actions;
export default topNavigationSlice.reducer;
