import React from "react";
import { Grid, Typography } from "@mui/material";

import MobileImage from "../../assets/mobile.png";
import AppStoreIcon from "../../assets/appStore.png";
import PlayStoreIcon from "../../assets/playstore.png";

import style from "./index.module.css";

import { InputField } from "../InputField";
import Button from "../Button";
import { FormattedMessage, useIntl } from "react-intl";

const DownloadAppBanner: React.FC = () => {
  const intl = useIntl();

  return (
    <Grid item className={style.container}>
      <Grid item className={style.mobileImageContainer}>
        <img src={MobileImage} alt="mobile image" />
      </Grid>
      <Grid item className={style.textContainer}>
        <Grid item className={style.downloadAppNowTitle}>
          <Typography component={"h4"}>
            <FormattedMessage
              id="download_the_app"
              defaultMessage="Download the App Now"
            />
          </Typography>
        </Grid>
        <Grid item className={style.appDescription}>
          <Typography component={"p"}>
            <FormattedMessage
              id="download_description"
              defaultMessage="Download description"
            />
          </Typography>
        </Grid>
        <Grid item className={style.getAppDownloadLink}>
          <InputField
            label={intl.formatMessage({
              id: "mobile_number",
              defaultMessage: "Mobile Number*",
            })}
            placeholder={intl.formatMessage({
              id: "mobile_number",
              defaultMessage: "Mobile Number*",
            })}
          />
          <Button>
            <FormattedMessage
              id="get_download_link"
              defaultMessage="Get Download Link"
            />
          </Button>
        </Grid>
        <Grid item className={style.downloadBtnContainer}>
          <Grid item className={style.playStoreIconContainer}>
            <Button>
              <Grid item>
                <img
                  src={PlayStoreIcon}
                  alt="store icon"
                  width={42}
                  height={42}
                />
              </Grid>
              <Grid item className={style.downloadBtnText}>
                <Typography
                  component={"p"}
                  className={style.downloadTextHeader}
                >
                  <FormattedMessage id="getit_on" defaultMessage="GET IT ON" />
                </Typography>
                <Typography
                  component={"p"}
                  className={style.downloadTextFooter}
                >
                  <FormattedMessage
                    id="google_play"
                    defaultMessage="Google Play"
                  />
                </Typography>
              </Grid>
            </Button>
          </Grid>
          <Button>
            <Grid item>
              <img src={AppStoreIcon} alt="store icon" width={42} height={42} />
            </Grid>
            <Grid item className={style.downloadBtnText}>
              <Typography component={"p"} className={style.downloadTextHeader}>
                <FormattedMessage
                  id="download_on_the"
                  defaultMessage="Download on the"
                />
              </Typography>
              <Typography component={"p"} className={style.downloadTextFooter}>
                <FormattedMessage id="app_store" defaultMessage="App Store" />
              </Typography>
            </Grid>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DownloadAppBanner;
