export const APIEndpoint = {
  onBoarding: {
    login: "user-service/v1/user/login",
    otpVerify: "user-service/v1/user/verify-otp",
  },
  patient: {
    profile: `user-service/v1/user/patient-profile`,
    updateMobileChangeOtp: `user-service/v1/user/patient-phone-otp`,
    patientMember: "user-service/v1/patient-member",
    doctorProfile: "doctor-service/v1/doctor/doctor-profile",
    specialitiesList: "doctor-service/v1/doctor/specialities",
    findDoctor: "doctor-service/v1/doctor/doctors?limit=100&offset=0",
    bookingAppointment: "booking-service/v1/booking-appointment",
    postReview: "booking-service/v1/review",
    home: "user-service/v1/user/home/detail/",
    faqTags: "user-service/v1/admins/faq/tag",
    faq: "user-service/v1/admins/faq?tag=",
    healthAssessmentList:
      "user-service/v1/admins/assessments?banner=true&type=",
    healthAssessmentQuestionList: "user-service/v1/admins/assessments/",
    healthAssessmentResult: "user-service/v1/admins/assessments/result",
    allOrders: "booking-service/v1/order-medicine?limit=100&offset=0&type=",
    pharmacyList: "doctor-service/v1/pharmacy/list?limit=100&offset=0&",
    patientAddress: "user-service/v1/patient-address",
    patientMedicalRecords: "booking-service/v1/medical-record/prescription",
    patientMedicalRecordsByMemberId:
      "booking-service/v1/medical-record/prescription?memberId=",
    medicineOrderPlaced: "booking-service/v1/order-medicine",
  },
  doctor: {
    config: `doctor-service/v1/config`,
    availabilitySetting: `doctor-service/v1/schedule-settings`,
    clinicList: `doctor-service/v1/doctor/clinic`,
    scheduleList: `doctor-service/v1/schedule`,
    scheduleSlots: `doctor-service/v1/schedule-slot`,
    checkNumber: "user-service/v1/user/search-patient",
    sendOtpPatientCreation: "user-service/v1/user/create-patient-otp",
    createPatient: "user-service/v1/user/create-patient",
    cancelScheduleAppointment:
      "booking-service/v1/booking-appointment/bulk-delete",
    doctorReviewList: "booking-service/v1/review?page=1&limit=100",
    reviewReply: "booking-service/v1/review/reply",
    reschedule: "booking-service/v1/booking-appointment/reschedule",
    prescriptionConfig: "booking-service/v1/config",
    createAndUpdatePrescription: "booking-service/v1/prescription",
    searchMedicineTest: "booking-service/v1/",
    medicalRecordList: "booking-service/v1/medical-record",
    saveMedicalRecord: "booking-service/v1/prescription/medical-record",
    uploadReportToS3: "booking-service/v1/s3-upload",
    searchPrescriptionAttribute: "booking-service/v1/prescription-attribute",
    searchAppointments: "booking-service/v1/booking-appointment/search",
    prescriptionTemplate: "booking-service/v1/prescription-template",
    doctorHospitalList: "doctor-service/v1/admins/doctor-hospital",
    doctorHospitalPatientList:
      "doctor-service/v1/hospital-patient?page=1&limit=100",
    hospitalRooms: "doctor-service/v1/admins/hospital/room",
    admitPatientOrDischarged: "doctor-service/v1/hospital-patient",
    admitPatientDetail: "doctor-service/v1/hospital-patient/patient",
    getActivePrescription: "booking-service/v1/prescription/active",
    getLastPrescription: "booking-service/v1/prescription/last",
    authTokenAgora: "booking-service/v1/agora",
    doctorAssociatedTeams: "user-service/v1/user/doctor-team",
    pushCallNotification: "user-service/v1/notification/firebase",
  },
  searchMember: "user-service/v1/user/search-user",
  accessToken: "user-service/v1/user/access-token",
  logout: "user-service/v1/user/logout",
};

export enum IAPIStatusType {
  SUCCESS = "success",
  ERROR = "error",
}
export enum IAPIStatusCode {
  INTERNAL_SERVER_ERROR = 500,
  UNAUTHENTICATED = 401,
  SUCCESS = 200,
  FORBIDDEN = 403,
  BAD_REQUEST = 400,
}

export const messages = {
  somethingWentWrong: "Something went wrong",
};
