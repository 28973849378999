import React from "react";
import { Grid, Typography } from "@mui/material";

import GetImages from "../../GetImages";

import style from "./index.module.css";

import { IPrescriptionTemplateList } from "../../../models/ApiRequestResponse/doctor";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { setViewPrescriptionTemplate } from "../../../store/slices/doctor/prescription";

const TemplateName: React.FC<IPrescriptionTemplateList> = (props) => {
  const { name } = props;
  const { create }: any = useAppSelector(
    (state) => state.prescription.template
  );
  const dispatch = useAppDispatch();

  const viewPrescriptionTemplate = () => {
    dispatch(setViewPrescriptionTemplate(props));
  };

  const getBgColor = () =>
    name === create?.name ? "var(--warm-neutral-50)" : "var(--neutral-100)";

  return (
    <Grid
      item
      className={style.container}
      sx={{ backgroundColor: getBgColor() }}
      onClick={viewPrescriptionTemplate}
    >
      <GetImages name="PrescriptionIcon" width="28" height="20" />
      <Typography component={"p"}>{name}</Typography>
    </Grid>
  );
};

export default TemplateName;
