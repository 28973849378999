import { combineReducers } from "redux";

import availabilitySettingReducer from "./doctor/availabilitySettings";
import appointmentReducer from "./doctor/appointment";
import scheduleReducer from "./doctor/schedule";
import doctorProfileReducer from "./doctor/profile";
import topNavigationReducer from "./doctor/topNavigation";
import prescriptionReducer from "./doctor/prescription";
import medicalRecordsReducer from "./doctor/medicalRecord";
import ipDashboardReducer from "./doctor/ipDashboard";
import checkMobilePatientExistOrNotReducer from "./doctor/checkMobilePatientExistOrNot";

import memberReducer from "./patient/member";
import findDoctorReducer from "./patient/findDoctor";
import homeModuleReducer from "./patient/home";
import healthAssessmentReducer from "./patient/healthAssessment";
import medicineReducer from "./patient/medicine";

import configReducer from "./config";
import onboardingSliceReducer from "./onboarding/onboarding";

import videoAppointmentReducer from "./videoAppointment";

const rootReducer = combineReducers({
  availability: availabilitySettingReducer,
  member: memberReducer,
  appointment: appointmentReducer,
  videoAppointment: videoAppointmentReducer,
  schedule: scheduleReducer,
  config: configReducer,
  findDoctor: findDoctorReducer,
  home: homeModuleReducer,
  healthAssessment: healthAssessmentReducer,
  medicine: medicineReducer,
  doctorProfile: doctorProfileReducer,
  topNavigation: topNavigationReducer,
  prescription: prescriptionReducer,
  medicalRecords: medicalRecordsReducer,
  ipDashboard: ipDashboardReducer,
  patientExistOrNot: checkMobilePatientExistOrNotReducer,
  onboarding: onboardingSliceReducer,
});

export default rootReducer;
