import React, { ChangeEvent, useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { FormattedMessage, useIntl } from "react-intl";

import style from "./index.module.css";

import GetImages from "../../../GetImages";
import Confirmation from "../../../Confirmation";

import { IRoleType } from "../../../../models/role";
import {
  IChangeHospitalModuleName,
  IDoctorConsultationSelection,
  IDoctorConsultationType,
  IDoctorProfileDropdownEnums,
} from "../../../../models/doctor";

import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  detail,
  fetchAvailabilitySettings,
} from "../../../../store/slices/doctor/availabilitySettings";
import { resetScheduleSelection } from "../../../../store/slices/doctor/schedule";
import {
  fetchFindDoctor,
  resetDoctorSearch,
  setDoctorSearch,
} from "../../../../store/slices/patient/findDoctor";
import {
  setChangeHospitalModuleName,
  setDoctorProfileDropdown,
} from "../../../../store/slices/doctor/topNavigation";
import {
  fetchPharmacyClinicList,
  setSearchPharmacy,
} from "../../../../store/slices/patient/medicine";

import { deleteAvailabilitySettingApi } from "../../../../utils/apis/doctor/availabilitySetting";
import { IAPIStatusCode } from "../../../../utils/apis/APIEndpointConfig";
import { storage } from "../../../../utils/Storage";
import useDebounce from "../../../../utils/hooks/useDebouncer";
import {
  doctorProfileDropdownDispatchingEnums,
  getLocation,
} from "../../../../utils";
import useCancelScheduleAppointments from "../../../../utils/hooks/useCancelScheduleAppointments";

interface INavItem {
  path: string;
  icon: React.ReactNode;
  title: string;
  subText: string;
  subTextHandler?: () => void;
  locationIcon: boolean;
  secondIcon: React.ReactNode;
  isShowSearch: boolean;
}
const TopNavigation: React.FC = () => {
  const navigate = useNavigate();

  const availabilityDetail = useAppSelector(
    (state) => state.availability.detail
  );
  const dispatch = useAppDispatch();

  const [confirmDeleteAvailability, setConfirmDeleteAvailability] =
    useState<boolean>(false);
  const roleType = storage.getSessionToken("roleType");
  const [navItem, setNavItem] = useState<INavItem>();
  const [showCancelSchedule, setShowCancelSchedule] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [selectedHospital, setSelectedHospital] = useState<string>("");
  const [location, setLocation] = useState({ latitude: 0, longitude: 0 });

  const { cancelScheduleAppointments } = useCancelScheduleAppointments();

  const debouncedSearch = useDebounce(search, 500);

  const subText = useLocation();

  const intl = useIntl();

  const scheduleSelection = useAppSelector((state) => state.schedule.selection);
  const isDoctorSearch = useAppSelector(
    (state) => state.findDoctor.isDoctorSearch
  );
  const { hospitals, selectedHospitalId } = useAppSelector(
    (state) => state.ipDashboard
  );
  const { isSearchPharmacy } = useAppSelector((state) => state.medicine);

  const navItems = {
    [IRoleType.PATIENT]: [
      {
        path: "/home",
        icon: <GetImages name="HomeNavIcon" width="36" height="36" />,
        title: storage.getSessionToken("name") ?? "Unknown",
        locationIcon: true,
        subText: "Durgapur, West Bengal",
        secondIcon: <GetImages name="MessageNavIcon" width="24" height="24" />,
        isShowSearch: true,
      },
      {
        path: "/find-doctor",
        icon: <GetImages name="DoctorNavIcon" width="36" height="36" />,
        title: intl.formatMessage({
          id: "find_doctors",
          defaultMessage: "Find Doctors",
        }),
        locationIcon: true,
        subText: "Durgapur, West Bengal",
        secondIcon: (
          <Grid item onClick={() => dispatch(setDoctorSearch())}>
            <GetImages name="NavSearchIcon" width="24" height="24" />
          </Grid>
        ),
        isShowSearch: false,
      },
      {
        path: "/medicines",
        icon: <GetImages name="MedicineNavIcon" width="36" height="36" />,
        title: intl.formatMessage({
          id: "get_medicines",
          defaultMessage: "Get Medicines",
        }),
        locationIcon: true,
        subText: "Durgapur, West Bengal",
        secondIcon: (
          <Grid item onClick={() => dispatch(setSearchPharmacy())}>
            <GetImages name="NavSearchIcon" width="24" height="24" />
          </Grid>
        ),
        isShowSearch: false,
      },
      {
        path: "/test-reports",
        icon: <GetImages name="TestNavIcon" width="36" height="36" />,
        title: intl.formatMessage({
          id: "book_tests",
          defaultMessage: "Book Tests",
        }),
        locationIcon: true,
        subText: "Durgapur, West Bengal",
        secondIcon: <GetImages name="CartNavIcon" width="24" height="24" />,
        isShowSearch: true,
      },
      {
        path: "/health-tips",
        icon: <GetImages name="TvNavIcon" width="36" height="36" />,
        title: intl.formatMessage({
          id: "health_hub",
          defaultMessage: "Health Hub",
        }),
        locationIcon: false,
        subText: "",
        secondIcon: null,
        isShowSearch: true,
      },
    ],
    [IRoleType.DOCTOR]: [
      {
        path: "/availability-settings",
        icon: <GetImages name="SettingOrangeIcon" width="36" height="36" />,
        title: intl.formatMessage({
          id: "availability_settings",
          defaultMessage: "Availability Settings",
        }),
        locationIcon: false,
        subText: "",
        secondIcon: (
          <Grid item onClick={() => navigate(-1)}>
            <CloseIcon sx={{ height: 24, weight: 24 }} />
          </Grid>
        ),
        isShowSearch: false,
      },
      {
        path: "/availability-create",
        icon: <GetImages name="SettingGreyedIcon" width="24" height="24" />,
        title: intl.formatMessage({
          id: "availability_settings",
          defaultMessage: "Availability Settings",
        }),
        locationIcon: false,
        subText: "",
        secondIcon: (
          <Grid item className={style.availabilityBtnsContainer}>
            {availabilityDetail && (
              <Grid
                item
                className={style.trashBtn}
                onClick={() =>
                  setConfirmDeleteAvailability(!confirmDeleteAvailability)
                }
              >
                <GetImages name="TrashIcon" width="24" height="24" />
              </Grid>
            )}
            <Grid
              item
              onClick={() => {
                navigate(-1);
                dispatch(detail(null));
              }}
            >
              <CloseIcon sx={{ height: 24, weight: 24 }} />
            </Grid>
          </Grid>
        ),
        isShowSearch: false,
      },
      {
        path: "/schedule",
        icon: <GetImages name="ScheduleNavIcon" width="36" height="36" />,
        title: intl.formatMessage({
          id: "schedule",
          defaultMessage: "Schedule",
        }),
        locationIcon: false,
        subText: "",
        secondIcon: (
          <Grid item className={style.scheduleTopIconContainer}>
            <Link to={"/search-appointments"}>
              <GetImages name="NavSearchIcon" width="24" height="24" />
            </Link>
            <Link to={"/availability-settings"}>
              <GetImages name="SettingGreyedIcon" width="24" height="24" />
            </Link>
          </Grid>
        ),
        isShowSearch: false,
      },
      {
        path: "/ip-dash",
        icon: <GetImages name="IpDashNavIcon" width="36" height="36" />,
        title: selectedHospital,
        locationIcon: false,
        subTextHandler: () => {
          dispatch(
            setChangeHospitalModuleName(IChangeHospitalModuleName.FROM_LIST)
          );
          dispatch(
            setDoctorProfileDropdown(
              doctorProfileDropdownDispatchingEnums[
                IDoctorProfileDropdownEnums.CHANGE_HOSPITAL
              ]
            )
          );
        },
        subText: "Change",
        secondIcon: (
          <Link to={"/search-ip"}>
            <GetImages name="NavSearchIcon" width="24" height="24" />
          </Link>
        ),
        isShowSearch: false,
      },
      {
        path: "/chats",
        icon: <GetImages name="ChatNavIcon" width="36" height="36" />,
        title: intl.formatMessage({
          id: "chats",
          defaultMessage: "Chats",
        }),
        locationIcon: false,
        subText: "",
        secondIcon: null,
        isShowSearch: true,
      },
    ],
  };

  const setNavItemsData = () => {
    if (roleType === IRoleType.PATIENT || roleType == IRoleType.DOCTOR) {
      navItems[roleType].forEach((item, index) => {
        if (item.path.includes(subText.pathname)) {
          setNavItem({
            ...item,
            title: selectedHospital ? selectedHospital : item.title,
          });
        }
      });
    }
  };

  const deleteAvailabilitySetting = async () => {
    const deleteAvailabilityResp = await deleteAvailabilitySettingApi(
      availabilityDetail?.id!
    );
    if (deleteAvailabilityResp.statusCode === IAPIStatusCode.SUCCESS) {
      navigate(-1);
      dispatch(fetchAvailabilitySettings());
      dispatch(detail(null));
      setConfirmDeleteAvailability(!confirmDeleteAvailability);
    }
  };

  const openCloseCancelAppointmentConfirmation = () =>
    setShowCancelSchedule(!showCancelSchedule);

  useEffect(() => {
    if (debouncedSearch.length === 0) {
      if (isDoctorSearch) {
        dispatch(resetDoctorSearch());
      }
      if (isSearchPharmacy) {
        dispatch(setSearchPharmacy());
        dispatch(
          fetchPharmacyClinicList({
            latitude: location.latitude,
            longitude: location.longitude,
            search,
          })
        );
      }
      return;
    }

    if (isDoctorSearch && debouncedSearch) {
      const locationAttriutes = `&latitude=${location?.latitude}&longitude=${location?.longitude}`;
      dispatch(fetchFindDoctor(`${locationAttriutes}&search=${search}`));
    }
    if (isSearchPharmacy && debouncedSearch) {
      dispatch(
        fetchPharmacyClinicList({
          latitude: location.latitude,
          longitude: location.longitude,
          search,
        })
      );
    }
  }, [debouncedSearch]);

  useEffect(() => {
    setNavItemsData();
  }, [subText, selectedHospital]);

  useEffect(() => {
    const hospital = hospitals.filter(
      (hospital) => hospital.hospital_id === selectedHospitalId
    )[0];
    setSelectedHospital(hospital?.name);
  }, [selectedHospitalId]);

  useEffect(() => {
    const locationVal = getLocation();
    setLocation(locationVal);
  }, []);
  return (
    <Grid item className={style.topNavigationContainer}>
      <Grid item className={style.topNavigationTitleContainer}>
        {scheduleSelection.isSelectionOn ? (
          <Grid item className={style.selectedScheduleContainer}>
            <CloseIcon
              sx={{ height: 24, weight: 24 }}
              onClick={() => dispatch(resetScheduleSelection())}
            />
            <Grid item className={style.selectedScheduleAppointmentCount}>
              <Typography component={"h4"}>
                <FormattedMessage
                  id="select_items"
                  defaultMessage="Select items"
                />
              </Typography>
              <Typography component={"p"}>
                {scheduleSelection.selectedSchedules.length}{" "}
                <FormattedMessage id="slots" defaultMessage="Slots" />,{" "}
                {scheduleSelection.selectedAppointments.length}{" "}
                <FormattedMessage
                  id="appointments"
                  defaultMessage="appointments"
                />
              </Typography>
            </Grid>
          </Grid>
        ) : isDoctorSearch || isSearchPharmacy ? (
          <Grid item>
            <input
              type="text"
              value={search}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setSearch(event.target.value)
              }
              placeholder={intl.formatMessage({
                id: "type_here",
                defaultMessage: "Type here",
              })}
              autoFocus
            />
          </Grid>
        ) : (
          <>
            <Grid item className={style.topNavigationIcon}>
              {navItem?.icon}
            </Grid>
            <Grid item className={style.topNavigationTextContainer}>
              <Typography component={"p"} className={style.topNavigationTitle}>
                {navItem?.title}
              </Typography>
              {navItem?.subText && (
                <Grid item className={style.topNavigationLocation}>
                  {navItem?.locationIcon && (
                    <GetImages
                      name="DoctorNavLocationIcon"
                      width="12"
                      height="12"
                    />
                  )}
                  <Typography
                    component={"p"}
                    className={style.topNavigationLocationText}
                    onClick={() =>
                      navItem?.subTextHandler && navItem?.subTextHandler()
                    }
                  >
                    {navItem?.subText}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </>
        )}
      </Grid>
      <Grid item className={style.topNavigationIconContainer}>
        {scheduleSelection.isSelectionOn ||
          (navItem?.isShowSearch && (
            <GetImages name="NavSearchIcon" width="24" height="24" />
          ))}
        {scheduleSelection.isSelectionOn ||
          (navItem?.secondIcon && (
            <Grid item className={style.topNavigationSecondIconContainer}>
              {navItem?.secondIcon}
            </Grid>
          ))}
        {scheduleSelection.isSelectionOn && (
          <Grid
            item
            className={style.topNavigationSecondIconContainer}
            onClick={() => setShowCancelSchedule(!showCancelSchedule)}
          >
            <GetImages name="CancelIcon" width="28" height="24" />
            <Typography component={"p"} className={style.cancelText}>
              <FormattedMessage id="cancel" defaultMessage="Cancel" />
            </Typography>
          </Grid>
        )}
      </Grid>
      {confirmDeleteAvailability && (
        <Confirmation
          isOpen={confirmDeleteAvailability}
          closeHandler={() =>
            setConfirmDeleteAvailability(!confirmDeleteAvailability)
          }
          title="delete_availability"
          alterMessage={"confirm_delete"}
          name={
            availabilityDetail?.type_of_consultation ===
            IDoctorConsultationType.IN_CLINIC
              ? availabilityDetail?.clinic_name ?? ""
              : IDoctorConsultationSelection.VIDEO_CONSULTATION
          }
          actionHandler={deleteAvailabilitySetting}
        />
      )}
      {showCancelSchedule && (
        <Confirmation
          isOpen={showCancelSchedule}
          closeHandler={openCloseCancelAppointmentConfirmation}
          title="cancel_appointments"
          alterMessage={"confirm_cancel_appoinment"}
          actionHandler={() => {
            cancelScheduleAppointments();
            openCloseCancelAppointmentConfirmation();
          }}
        />
      )}
    </Grid>
  );
};

export default TopNavigation;
