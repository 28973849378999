import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import ProfileStatusBadge from "../../../components/ProfileStatusBadge";
import GetImages from "../../../components/GetImages";
import TagChip from "../../../components/TagChip";
import DoctorReviews from "../../../components/DoctorReviews";
import Loader from "../../../components/Loader";
import ViewDoctorClinic from "./ViewDoctorClinic";
import DoctorFeedBack from "../../../components/DoctorFeedBack";

import style from "./index.module.css";

import FirstVideoThumbNail from "../../../assets/videoReviews/1.png";
import SecondVideoThumbNail from "../../../assets/videoReviews/2.png";
import ThirdVideoThumbNail from "../../../assets/videoReviews/3.png";
import FourthVideoThumbNail from "../../../assets/videoReviews/4.png";

import { IDoctorProfileStatus } from "../../../models/doctor";

import { doctorDegreeName, doctorSpeakLanguages } from "../../../utils";
import { storage } from "../../../utils/Storage";
import { postReviewToDoctorApi } from "../../../utils/apis/patient/doctor";
import { INotifyEnum, notify } from "../../../utils/toaster";
import useIsMobile from "../../../utils/hooks/useIsMobile";

import { IRoleType } from "../../../models/role";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  fetchDoctorProfile,
  fetchDoctorReviewRatingList,
} from "../../../store/slices/doctor/profile";

const doctorProfiles = {
  videoThumbnail: [
    FirstVideoThumbNail,
    SecondVideoThumbNail,
    ThirdVideoThumbNail,
    FourthVideoThumbNail,
  ],
};

interface IParams {
  [key: string]: string;
}
interface IProps {
  doctorID?: string;
}
const DoctorProfile: React.FC<IProps> = (props) => {
  const { doctorID } = props;
  const [viewMore, setViewMore] = useState<boolean>(false);
  const [viewMoreClinics, setViewMoreClinics] = useState<boolean>(false);
  const [isSendFeedbackShow, setSendFeedbackShow] = useState<boolean>(false);
  const [selectedStar, setSelectedStar] = useState<number>(0);
  const [reviewMessage, setReviewMessage] = useState<string>("");

  const { isMobile } = useIsMobile();

  const keys = ["roleType", "bookingId"];
  const [roleType, bookingId] = keys.map((keyItem) =>
    storage.getSessionToken(keyItem)
  );

  const navigate = useNavigate();

  const doctorDetail = useParams<IParams>();
  const doctorId = doctorDetail.doctorId ?? doctorID;

  const backToFindDoctorPage = () => {
    if (roleType === IRoleType.DOCTOR) {
      navigate("/profile", { state: { navItem: 3 } });
      return;
    }
    navigate("/find-doctor", { state: { navItem: 1 } });
  };

  const dispatch = useAppDispatch();
  const doctorProfileInfo = useAppSelector((state) => state.doctorProfile);
  const doctorProfile = doctorProfileInfo?.detail;

  const sendFeedbackToDoctorHandler = async () => {
    const payload = {
      doctorId: doctorId!,
      bookingId: bookingId!,
      noOfStars: selectedStar,
      reviewMessage,
    };
    const sendFeedbackToDoctorResponse = await postReviewToDoctorApi(payload);
    if ("result" in sendFeedbackToDoctorResponse) {
      notify(INotifyEnum.SUCCESS, sendFeedbackToDoctorResponse?.message);
      dispatch(fetchDoctorReviewRatingList({ doctorId: doctorId! }));
    } else {
      notify(INotifyEnum.ERROR, sendFeedbackToDoctorResponse?.message);
    }
    setReviewMessage("");
    setSendFeedbackShow(!isSendFeedbackShow);
  };

  useEffect(() => {
    if (roleType === IRoleType.PATIENT || !doctorProfileInfo?.detail) {
      dispatch(fetchDoctorProfile(doctorId));
      dispatch(fetchDoctorReviewRatingList({ doctorId: doctorId! }));
    }
  }, []);

  if (doctorProfileInfo?.isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Grid item className={style.container}>
        <img
          src={doctorProfile?.clinics[0]?.photo_url}
          alt="clinicImage"
          className={style.clinicImage}
        />
        {isMobile && (
          <Grid
            item
            className={style.backNavigation}
            onClick={backToFindDoctorPage}
          >
            <GetImages name="LeftArrowIcon" width="16" height="16" />
          </Grid>
        )}
      </Grid>
      <Grid item className={style.container}>
        <Grid item className={style.doctorImageContainer}>
          <img
            src={doctorProfile?.photo_url}
            alt="doctorPhoto"
            width={80}
            height={80}
          />
          <Grid item className={style.doctorProfileStatus}>
            <ProfileStatusBadge status={IDoctorProfileStatus.VERIFIED} />
          </Grid>
        </Grid>
        <Grid item className={style.doctorDetailContainer}>
          <Typography component={"h4"}>{doctorProfile?.name}</Typography>
          <Typography component={"h6"}>
            {doctorProfile?.experience_years} yrs • {doctorProfile?.speciality}{" "}
            •{" "}
            {doctorProfile?.qualification_details &&
              doctorDegreeName(doctorProfile?.qualification_details)}
          </Typography>
          <Typography component={"h6"}>
            {doctorProfile?.language &&
              doctorSpeakLanguages(doctorProfile?.language)}
          </Typography>
          <Typography component={"p"}>
            {doctorProfile?.clinics[0]?.address}
          </Typography>
          {doctorProfile && doctorProfile?.clinics.length > 1 && (
            <Grid item>
              <Typography
                component={"p"}
                className={style.totalClinicCount}
                onClick={() => setViewMoreClinics(!viewMoreClinics)}
              >
                {doctorProfile?.clinics.length} more clinics
              </Typography>
            </Grid>
          )}
          <Grid item>
            <Typography component={"p"} className={style.doctorAbout}>
              {viewMore
                ? doctorProfile?.about_me
                : doctorProfile?.about_me.slice(0, 200)}
            </Typography>
            <Grid
              item
              className={style.viewMoreContainer}
              onClick={() => setViewMore(!viewMore)}
            >
              <GetImages name="EyeSmallIcon" width="20" height="16" />
              <Typography component={"p"}>
                View {viewMore ? "less" : "more"}
              </Typography>
            </Grid>
          </Grid>
          <Grid item className={style.tagsContainer}>
            <Typography component={"p"}>I can help you with</Typography>
            <Grid item className={style.tagItemsContainer}>
              {doctorProfile?.tags.split(",").map((tagName, index) => (
                <TagChip tagName={tagName} key={`${tagName}-${index}`} />
              ))}
            </Grid>
          </Grid>
          <Grid item>
            <Grid item className={style.reviewCountHeader}>
              <GetImages name="LikeIcon" width="20" height="16" />
              <Typography component={"p"}>
                {doctorProfile?.reviews} Reviews
              </Typography>
            </Grid>
            <Grid item className={style.expertiseContainer}>
              <Typography component={"p"}>
                {doctorProfile?.expertise}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {roleType === IRoleType.PATIENT && (
          <>
            <Grid item className={style.videoReviewContainer}>
              <Grid item className={style.videoReviewHeader}>
                <Typography component={"h6"}>Video Reviews</Typography>
                <Typography component={"p"}>View All</Typography>
              </Grid>
              <Grid item className={style.videoReviewThumbnail}>
                {doctorProfiles.videoThumbnail.map((thumbnail, index) => (
                  <img src={thumbnail} alt="video Thumb" />
                ))}
              </Grid>
            </Grid>
            <Grid item>
              <DoctorReviews />
            </Grid>
            <Typography
              component={"p"}
              onClick={() => setSendFeedbackShow(!isSendFeedbackShow)}
            >
              Send Feedback
            </Typography>
          </>
        )}
        {viewMoreClinics && (
          <ViewDoctorClinic
            isOpen={viewMoreClinics}
            closeModalHandler={() => setViewMoreClinics(!viewMoreClinics)}
            clinics={doctorProfile?.clinics!}
          />
        )}
        {isSendFeedbackShow && (
          <DoctorFeedBack
            isOpen={isSendFeedbackShow}
            closeHandler={() => setSendFeedbackShow(!isSendFeedbackShow)}
            setSelectedStar={setSelectedStar}
            reviewMessage={reviewMessage}
            setReviewMessage={setReviewMessage}
            submitHandler={sendFeedbackToDoctorHandler}
          />
        )}
      </Grid>
    </>
  );
};

export default DoctorProfile;
