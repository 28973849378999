import React, { useCallback, useEffect, useState } from "react";
import {
  BottomNavigationAction,
  Grid,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { BottomNavigation as Navigation } from "@mui/material";

import GetImages from "../../../GetImages";
import Button from "../../../Button";
import DoctorProfileDetail from "../../../../views/common/DoctorProfile";
import DoctorProfile from "../../../../views/doctor/Profile";
import Dialog from "../../../Dialog";
import CreateEditTemplate from "../../../../views/doctor/Prescription/CreateEditTemplate";
import AvailabilitySettingWeb from "../../../../views/doctor/AvailabilitySettings/AvailabilitySettingWeb";
import BeforeAdmitPatientPopUps from "../../../../views/doctor/IPDashboard/BeforeAdmitPatientPopUps";
import AdmitPatient from "../../../../views/doctor/IPDashboard/AdmitPatient";

import { IRoleType } from "../../../../models/role";
import {
  IAddPatient,
  IDoctorProfileDropdownEnums,
  IModalShowHide,
  IPatientExistOrNotModule,
} from "../../../../models/doctor";

import style from "./index.module.css";

import { storage } from "../../../../utils/Storage";
import { doctorProfileDropdownDispatchingEnums } from "../../../../utils";

import NavImage from "../../../../assets/navImage.png";

import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  setAddPatient,
  setDoctorProfileDropdown,
} from "../../../../store/slices/doctor/topNavigation";
import {
  resetPatientDetailErrorMessages,
  setModuleName,
} from "../../../../store/slices/doctor/checkMobilePatientExistOrNot";
import { setEditAdmitPatient } from "../../../../store/slices/doctor/ipDashboard";
import { FormattedMessage, useIntl } from "react-intl";

enum DropdownType {
  ADD_PATIENT = "ADD_PATIENT",
  DOCTOR_PROFILE = "DOCTOR_PROFILE",
}
const navigation = {
  [IRoleType.PATIENT]: [
    {
      path: "/home",
      icon: <GetImages name="HomeIcon" width="24" height="24" />,
      selectedIcon: (
        <GetImages name="HomeSelectedIcon" width="24" height="24" />
      ),
      label: "home",
    },
    {
      path: "/find-doctor",
      icon: <GetImages name="DoctorIcon" width="24" height="24" />,
      selectedIcon: (
        <GetImages name="DoctorSelectedIcon" width="24" height="24" />
      ),
      label: "doctors",
    },
    {
      path: "/medicines",
      icon: <GetImages name="MedicineIcon" width="24" height="24" />,
      selectedIcon: (
        <GetImages name="MedicineSelectedIcon" width="24" height="24" />
      ),
      label: "medicines",
    },
    {
      path: "/test-reports",
      icon: <GetImages name="TestIcon" width="24" height="24" />,
      selectedIcon: (
        <GetImages name="TestSelectedIcon" width="24" height="24" />
      ),
      label: "tests",
    },
    {
      path: "/health-tips",
      icon: <GetImages name="TvIcon" width="24" height="24" />,
      selectedIcon: <GetImages name="TvSelectedIcon" width="24" height="24" />,
      label: "health",
    },
  ],
  [IRoleType.DOCTOR]: [
    {
      path: "/schedule",
      icon: <GetImages name="ScheduleIcon" width="24" height="24" />,
      selectedIcon: (
        <GetImages name="ScheduleSelectedIcon" width="24" height="24" />
      ),
      label: "schedule",
    },
    {
      path: "/ip-dash",
      icon: <GetImages name="IpDashIcon" width="24" height="24" />,
      selectedIcon: (
        <GetImages name="IpDashSelectedIcon" width="24" height="24" />
      ),
      label: "ip_dashboard",
    },
  ],
};

const doctorProfileItems = [
  {
    path: "",
    iconName: "SmileSmallIcon",
    text: "profile",
    identifier: IDoctorProfileDropdownEnums.PROFILE,
  },
  {
    path: "",
    iconName: "SettingSmallIcon",
    text: "availability",
    identifier: IDoctorProfileDropdownEnums.AVAILABILITY,
  },
  {
    path: "",
    iconName: "StarSmallIcon",
    text: "reviews",
    identifier: IDoctorProfileDropdownEnums.REVIEW,
  },
  {
    path: "",
    iconName: "ClinicTeamSmallIcon",
    text: "clinical_team",
    identifier: IDoctorProfileDropdownEnums.CLINICAL_TEAM,
  },
  {
    path: "",
    iconName: "TemplatesSmallIcon",
    text: "templates",
    identifier: IDoctorProfileDropdownEnums.TEMPLATES,
  },
];

interface INavItemInfo {
  path: string;
  icon: React.ReactNode;
  selectedIcon: React.ReactNode;
  label: string;
}
const TopNavigation: React.FC = () => {
  const location = useLocation();

  const [navIndex, setNavIndex] = useState<number>(
    location.state?.navItem ?? 0
  );
  const [navItems, setNavItems] = useState<INavItemInfo[]>();
  const [showAddPatient, setShowAddPatient] = useState(null);
  const [showDoctorProfile, setShowDoctorProfile] = useState(null);
  const isShowAddPatient = Boolean(showAddPatient);
  const isShowDoctorProfile = Boolean(showDoctorProfile);

  const dispatch = useAppDispatch();
  const doctorProfileDropdown = useAppSelector(
    (state) => state.topNavigation.doctorProfile
  );
  const { mobileCheck, admiPatient } = useAppSelector(
    (state) => state.topNavigation.addPatient
  );

  const intl = useIntl();
  const navigate = useNavigate();
  const roleType = storage.getSessionToken("roleType");

  const openCloseMobileCheckOTPVerify = (
    field: IAddPatient,
    value: boolean
  ) => {
    dispatch(
      setAddPatient({
        field,
        value,
      })
    );
  };

  const addPatientInfo = [
    {
      iconName: "ScheduleSelectedSmallIcon",
      text: "appointment",
      handler: () => {
        dispatch(setModuleName(IPatientExistOrNotModule.APPOINTMENT));
        openCloseMobileCheckOTPVerify(IAddPatient.APPOINTMENT, true);

        handleShowHideDropDown(IModalShowHide.HIDE, DropdownType.ADD_PATIENT);
      },
    },
    {
      iconName: "ClinicSmallIcon",
      text: "admit_patient",
      handler: () => {
        dispatch(setModuleName(IPatientExistOrNotModule.ADMIT_PATIENT));
        openCloseMobileCheckOTPVerify(IAddPatient.MOBILE_CHECK, true);
        handleShowHideDropDown(IModalShowHide.HIDE, DropdownType.ADD_PATIENT);
        dispatch(setEditAdmitPatient(false));
        dispatch(resetPatientDetailErrorMessages());
      },
    },
  ];

  const setNavigationItemsByRoleType = () => {
    if (roleType === IRoleType.PATIENT || roleType === IRoleType.DOCTOR) {
      setNavItems(navigation[roleType]);
    }
  };

  const navigateToPage = () => {
    if (roleType === IRoleType.PATIENT || roleType === IRoleType.DOCTOR) {
      navigate(navigation[roleType][navIndex].path);
    }
  };

  const handleShowHideDropDown = (
    type: IModalShowHide,
    dropDownType: DropdownType,
    event?: any
  ) => {
    if (type === IModalShowHide.HIDE) {
      dropDownType === DropdownType.ADD_PATIENT
        ? setShowAddPatient(null)
        : setShowDoctorProfile(null);
      return;
    }
    dropDownType === DropdownType.ADD_PATIENT
      ? setShowAddPatient(event.currentTarget)
      : setShowDoctorProfile(event.currentTarget);
  };

  const openCloseDoctorProfileDropdown = useCallback(
    (identifier: IDoctorProfileDropdownEnums) => {
      setShowDoctorProfile(null);
      setShowAddPatient(null);
      dispatch(
        setDoctorProfileDropdown(
          doctorProfileDropdownDispatchingEnums[identifier]
        )
      );
    },
    []
  );

  useEffect(() => {
    setNavigationItemsByRoleType();
  }, []);

  useEffect(() => {
    navigateToPage();
  }, [navIndex]);

  return (
    <Grid item className={style.navigationContainer}>
      <Navigation
        showLabels
        value={navIndex}
        sx={{ justifyContent: "flex-start", background: "transparent" }}
        onChange={(event, newValue) => setNavIndex(newValue)}
      >
        {navItems &&
          navItems.map((nav, index) => (
            <BottomNavigationAction
              label={intl.formatMessage({
                id: nav.label,
                defaultMessage: "Nav Items",
              })}
              icon={navIndex === index ? nav.selectedIcon : nav.icon}
              key={index}
              sx={{
                color: "var(--gray-400)",
                fontSize: "1.6rem",
                lineHeight: "1.6rem",
                fontWeight: "600",
                flexDirection: "row",
                alignItems: "center",
                width: "15rem",
                "& .MuiBottomNavigationAction-label": {
                  marginLeft: "0.8rem",
                },
                "&.Mui-selected": {
                  color: "var(--orange-500)",
                  fontSize: "1.6rem",
                  fontWeight: "600",
                },
              }}
            />
          ))}
      </Navigation>
      <Grid item className={style.searchAppointmentInputContainer}>
        <input
          placeholder={intl.formatMessage({
            id: "search_appointments_here",
            defaultMessage: "Search appointments here",
          })}
          onFocus={() => navigate("/search")}
        />
        <GetImages name="NavSearchIcon" width="24" height="24" />
      </Grid>
      <Grid item className={style.addPatientDoctorImageContainer}>
        <Grid item className={style.btnContainer}>
          <Button
            btnTrigger={(event) =>
              handleShowHideDropDown(
                IModalShowHide.SHOW,
                DropdownType.ADD_PATIENT,
                event
              )
            }
          >
            <FormattedMessage id="add_patient" defaultMessage="Add Patient" />
          </Button>

          <Menu
            anchorEl={showAddPatient}
            open={isShowAddPatient}
            onClose={() =>
              handleShowHideDropDown(
                IModalShowHide.HIDE,
                DropdownType.ADD_PATIENT
              )
            }
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            {addPatientInfo.map((patient, index) => (
              <MenuItem onClick={patient.handler} key={index}>
                <ListItemIcon sx={{ minWidth: "auto" }}>
                  <GetImages name={patient.iconName} width="24" height="20" />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography className={style.dropdownText} component={"p"}>
                      <FormattedMessage
                        id={patient.text}
                        defaultMessage="Add Patient Drop"
                      />
                    </Typography>
                  }
                />
              </MenuItem>
            ))}
          </Menu>
        </Grid>
        <Grid item className={style.doctorImageContainer}>
          <img
            src={NavImage}
            width={48}
            height={48}
            onClick={(event) =>
              handleShowHideDropDown(
                IModalShowHide.SHOW,
                DropdownType.DOCTOR_PROFILE,
                event
              )
            }
          />
          <Menu
            anchorEl={showDoctorProfile}
            open={isShowDoctorProfile}
            onClose={() =>
              handleShowHideDropDown(
                IModalShowHide.HIDE,
                DropdownType.DOCTOR_PROFILE
              )
            }
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            {doctorProfileItems.map((doctorProfile, index) => (
              <MenuItem
                onClick={() =>
                  openCloseDoctorProfileDropdown(doctorProfile.identifier)
                }
                key={index}
              >
                <ListItemIcon sx={{ minWidth: "auto" }}>
                  <GetImages
                    name={doctorProfile.iconName}
                    width="24"
                    height="20"
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography className={style.dropdownText} component={"p"}>
                      <FormattedMessage
                        id={doctorProfile.text}
                        defaultMessage="Drop Down Item"
                      />
                    </Typography>
                  }
                />
              </MenuItem>
            ))}
          </Menu>
        </Grid>
      </Grid>
      {doctorProfileDropdown?.isProfileOpen && (
        <Dialog
          isOpen={doctorProfileDropdown?.isProfileOpen}
          closeHandler={() =>
            openCloseDoctorProfileDropdown(IDoctorProfileDropdownEnums.PROFILE)
          }
        >
          <DoctorProfileDetail />
        </Dialog>
      )}
      {doctorProfileDropdown?.isReviewOpen && (
        <Dialog
          isOpen={doctorProfileDropdown?.isReviewOpen}
          closeHandler={() =>
            openCloseDoctorProfileDropdown(IDoctorProfileDropdownEnums.REVIEW)
          }
        >
          <DoctorProfile />
        </Dialog>
      )}
      {doctorProfileDropdown.isTemplatesOpen && (
        <Dialog
          isOpen={doctorProfileDropdown?.isTemplatesOpen}
          closeHandler={() =>
            openCloseDoctorProfileDropdown(
              IDoctorProfileDropdownEnums.TEMPLATES
            )
          }
        >
          <CreateEditTemplate />
        </Dialog>
      )}
      {doctorProfileDropdown.isAvailabilityOpen && (
        <Dialog
          isOpen={doctorProfileDropdown?.isAvailabilityOpen}
          closeHandler={() =>
            openCloseDoctorProfileDropdown(
              IDoctorProfileDropdownEnums.AVAILABILITY
            )
          }
        >
          <AvailabilitySettingWeb />
        </Dialog>
      )}
      {mobileCheck && <BeforeAdmitPatientPopUps />}
      {admiPatient && (
        <AdmitPatient
          isOpen={admiPatient}
          closeHandler={() => {
            openCloseMobileCheckOTPVerify(IAddPatient.ADMIT_PATIENT, false);
            dispatch(resetPatientDetailErrorMessages());
          }}
        />
      )}
    </Grid>
  );
};

export default TopNavigation;
