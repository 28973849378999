import React from "react";
import { InputAdornment, Typography } from "@mui/material";

import { InputField } from "../../InputField";

import style from "./index.module.css";
import { FormattedMessage, useIntl } from "react-intl";

interface IProps {
  type?: string;
  value: string;
  label: string;
  adornText: string;
  onClickAdorn?: () => void;
  disabled?: boolean;
  disabledSelect?: boolean;
}

const InputWithAdornedText: React.FC<IProps> = (props) => {
  const {
    type,
    adornText,
    disabled = false,
    label,
    onClickAdorn,
    value,
    disabledSelect = false,
  } = props;
  const intl = useIntl();
  return (
    <InputField
      type={type}
      value={value}
      label={intl.formatMessage({
        id: label,
        defaultMessage: "Adorned Text Label",
      })}
      disabled={disabled}
      InputProps={{
        endAdornment: (
          <>
            {!disabledSelect && (
              <InputAdornment position="end">
                <Typography
                  component="p"
                  className={style.verifiedMobileNumberSelectText}
                  onClick={onClickAdorn}
                >
                  <FormattedMessage id={adornText} defaultMessage="adornText" />
                </Typography>
              </InputAdornment>
            )}
          </>
        ),
      }}
      fullWidth
    />
  );
};

export default InputWithAdornedText;
