import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { agoraAuthTokenApi } from "../../utils/apis/doctor/appointment";

import { IDoctorAssociatedClinicMemberProfile } from "../../models/ApiRequestResponse/doctor";

export const fetchAuthTokenAgora = createAsyncThunk(
  "videoAppointment",
  async (channelName: string) => {
    const response = await agoraAuthTokenApi(channelName);
    return response;
  }
);

const videoAppointmentSlice = createSlice({
  name: "videoAppointment",
  initialState: {
    token: "" as string,
    channelName: "" as string,
    addMemberShow: false as boolean,
    isVideoCallInitiated: false as boolean,
    isCameraOn: true as boolean,
    isVideoMinimizedOrPrescInitiated: false as boolean,
    isVideoMinimizedAndChatSelected: false as boolean,
    isVideoMinimizedAndPrescriptionSelected: false as boolean,
    callerDetails: null as any,
    addToCallMember: null as IDoctorAssociatedClinicMemberProfile | null,
    fcmDeviceToken: "" as string,
    isLoading: false as boolean,
  },
  reducers: {
    resetToken: (state) => {
      state.token = "";
    },
    setChannelName: (state, action) => {
      state.channelName = action.payload;
    },
    showHideAddMember: (state) => {
      state.addMemberShow = !state.addMemberShow;
    },
    setIsVideoCallInitiated: (state) => {
      state.isVideoCallInitiated = !state.isVideoCallInitiated;
    },
    setIsCameraOn: (state) => {
      state.isCameraOn = !state.isCameraOn;
    },
    setIsVideoMinimizedOrPrescInitiated: (state,action) => {
      state.isVideoMinimizedOrPrescInitiated = action.payload.value;
    },
    setIsVideoUnMinimized: (state,action) => {
      state.isVideoMinimizedOrPrescInitiated = !action.payload.value;
      state.isVideoMinimizedAndChatSelected = !action.payload.value; 
      state.isVideoMinimizedAndPrescriptionSelected = !action.payload.value;      
    },
    setIsVideoCallChatInitiated: (state,action) => {
      state.isVideoMinimizedAndChatSelected = action.payload.value;
      state.isVideoMinimizedAndPrescriptionSelected = action.payload.value?!action.payload.value:action.payload.value;
    },
    setIsVideoCallPrescriptionInitiated: (state,action) => {
      state.isVideoMinimizedAndPrescriptionSelected = action.payload.value;
      state.isVideoMinimizedAndChatSelected = action.payload.value?!action.payload.value:action.payload.value;
    },
    setCallerDetails: (state, action) => {
      state.callerDetails = action.payload;
    },
    setAddToCallMember: (state, action) => {
      state.addToCallMember = action.payload;
    },
    setFCMDeviceToken: (state, action) => {
      state.fcmDeviceToken = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAuthTokenAgora.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAuthTokenAgora.fulfilled, (state, action) => {
        state.isLoading = false;
        if ("result" in action.payload) {
          state.token = action.payload.result;
        }
      })
      .addCase(fetchAuthTokenAgora.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const {
  resetToken,
  setChannelName,
  showHideAddMember,
  setIsVideoCallInitiated,
  setIsCameraOn,
  setIsVideoMinimizedOrPrescInitiated,
  setIsVideoCallChatInitiated,
  setIsVideoUnMinimized,
  setIsVideoCallPrescriptionInitiated,
  setCallerDetails,
  setAddToCallMember,
  setFCMDeviceToken,
} = videoAppointmentSlice.actions;
export default videoAppointmentSlice.reducer;
