import { createSlice } from "@reduxjs/toolkit";

interface ISteps {
  language: boolean;
  tutorial: boolean;
  mobileOTPVerify: boolean;
}

interface IOnboarding {
  steps: ISteps;
}
const patientOnboardingSlice = createSlice({
  name: "onboarding",
  initialState: {
    steps: {
      language: true,
      tutorial: false,
      mobileOTPVerify: false,
    } as ISteps,
  },
  reducers: {
    setSteps: (state: IOnboarding, action) => {
      const key: keyof ISteps = action.payload;
      let stepsObj: ISteps = {
        language: false,
        tutorial: false,
        mobileOTPVerify: false,
      };

      state.steps = {
        ...stepsObj,
        [action.payload]: !state.steps[key],
      };
    },
  },
  extraReducers: (builder) => {},
});

export const { setSteps } = patientOnboardingSlice.actions;
export default patientOnboardingSlice.reducer;
