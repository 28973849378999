import React, { useEffect, useState } from "react";

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    let x = window.matchMedia("(max-width: 650px)");
    if (x.matches) {
      setIsMobile(true);
    }
  }, []);

  return { isMobile };
};

export default useIsMobile;
