import React from "react";
import { Typography } from "@mui/material";

import style from "./index.module.css";

import { IDoctorProfileStatus } from "../../models/doctor";

import { transformClasses } from "../../utils";

interface IProps {
  status: IDoctorProfileStatus;
}

const ProfileStatusBadge: React.FC<IProps> = (props) => {
  const { status } = props;
  switch (status) {
    case IDoctorProfileStatus.VERIFIED:
      return (
        <Typography
          component={"p"}
          className={transformClasses(style.status, style.statusVerified)}
        >
          Verified
        </Typography>
      );
    case IDoctorProfileStatus.ASK_FREE:
      return (
        <Typography
          component={"p"}
          className={transformClasses(style.status, style.statusAskFree)}
        >
          Ask free
        </Typography>
      );
    case IDoctorProfileStatus.NEW:
      return (
        <Typography
          component={"p"}
          className={transformClasses(style.status, style.statusNew)}
        >
          New
        </Typography>
      );
    default:
      return (
        <Typography
          component={"p"}
          className={transformClasses(style.status, style.statusNew)}
        >
          New
        </Typography>
      );
  }
};

export default ProfileStatusBadge;
