import React from "react";
import { Grid } from "@mui/material";

import style from "./index.module.css";

interface IProps {
  tagName: string;
}
const TagChip: React.FC<IProps> = (props) => {
  const { tagName } = props;
  return (
    <Grid item className={style.tagNameContainer}>
      {tagName}
    </Grid>
  );
};

export default TagChip;
