import dayjs, { Dayjs } from "dayjs";

import {
  IAdmitPatientType,
  IDays,
  IDoctorProfileDropdownEnums,
  IDoctorScheduleConsultationType,
  IKeyPairsValues,
  IPDashboardFilterType,
  IScheduleAppointmentFilterEnum,
  IUniversalSearchEnum,
} from "../models/doctor";
import { ILanguageEnums } from "../models/onboarding";
import {
  IHealthAssesmentTypeEnums,
  IQualificationDetail,
} from "../models/ApiRequestResponse/patient";
import {
  IAdmittedPatientDetail,
  IScheduleAvailabilitySetting,
} from "../models/ApiRequestResponse/doctor";
import { IOperatingSystemEnums } from "../models";
import { IConsultationTypes } from "../models/patient";

import { mapUrl } from "./Config";

import { storage } from "./Storage";

const dayList = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const tranformIndexesToDays = (arr: string[]) => {
  let isComplete = false,
    result = [];
  for (let i = 0; i < arr.length; i++) {
    isComplete = +arr[i] - 1 === i;
    result.push(dayList[+arr[i] - 1]);
  }
  return { isComplete, list: result };
};

const tranformDaysToIndexes = (dayIndexesArr: string[]) => {
  return dayIndexesArr.map((dayIndex, index) =>
    dayList[+dayIndex - 1].toString()
  );
};

const convertDaysToRanges = (arr: string[]) => {
  const { isComplete, list } = tranformIndexesToDays(arr);

  if (isComplete) {
    if (list.length < 3) {
      if (list.length === 1) {
        return list[0];
      }
      return `${list[0]},${list[1]}`;
    } else {
      return `${list[0]}-${list[list.length - 1]}`;
    }
  } else {
    return `${list.join(",")}`;
  }
};

const timeInAmPmFormat = (value: string) => {
  return dayjs(`${dayjs().format("YYYY-MM-DD")}T${value}`).format("hh:mm a");
};

const transformClasses = function (...args: any) {
  return args.join(" ");
};

const getAvailabilityListByDay = (
  list: IScheduleAvailabilitySetting[],
  day: IDays
) => {
  const dayIndexInList = dayList.indexOf(day) + 1;
  return list.filter((item, index) =>
    item.days.includes(dayIndexInList.toString())
  );
};

const languagesList = [
  {
    label: "English",
    value: ILanguageEnums.ENG,
  },
  {
    label: "हिंदी",
    value: ILanguageEnums.HIN,
  },
  {
    label: "ગુજરાતી",
    value: ILanguageEnums.GUJ,
  },
  {
    label: "বাংলা",
    value: ILanguageEnums.BEN,
  },
];

const doctorSpeakLanguages = (langList: string[]) => {
  if (langList.length === 0) return "";
  const langRes: string[] = [];
  languagesList.forEach((list, index) => {
    langList.forEach((lis, ind) => {
      if (list.value.includes(lis)) {
        langRes.push(list.label);
      }
    });
  });
  return langRes.join(" • ");
};

const doctorDegreeName = (qual: IQualificationDetail[]) => {
  return qual.map((list, index) => list.degree_name).join(",");
};

const getDateTime = (value: string) => {
  const now = dayjs(value);
  if (now.isSame(dayjs(), "day")) {
    return "Today";
  } else if (now.isSame(dayjs().add(1, "day"), "day")) {
    return "Tomorrow";
  } else {
    return now.format("MMM D, YYYY h:mm a");
  }
};

const getNextSixDates = () => {
  const dates = [];
  const today = dayjs();
  for (let i = 0; i <= 6; i++) {
    const nextDate = today.clone().add(i, "days");
    dates.push({
      date: nextDate.format("DD MMM"),
      day: i === 0 ? "Today" : nextDate.format("ddd"),
    });
  }
  return dates;
};

const findDoctorFilterListing = [
  {
    label: "Speciality",
    value: "speciality",
    isSelection: true,
  },
  {
    label: "In-Clinic",
    value: "In-Clinic",
    isSelection: false,
  },
  {
    label: "Video Call",
    value: "Video Call",
    isSelection: false,
  },
  {
    label: "Experince",
    value: "experience",
    isSelection: true,
  },
  {
    label: "Distance",
    value: "distance",
    isSelection: true,
  },
  {
    label: "Language",
    value: "language",
    isSelection: true,
  },
];

const openClinicMapLocation = (lat: number, lng: number) => {
  const url = `${mapUrl}${lat},${lng}`;
  window.open(url, "_blank");
};

const transformDateToMMDDYYY = (date: any) => {
  return dayjs(date).format("MMM D, YYYY");
};

const base64ToFile = (base64String: string, fileName: string) => {
  // Split the base64 string into two parts: data type and data content
  const arr = base64String.split(",");
  // Extract the MIME type from the data type (the first part of the string)
  const mime = arr[0].match(/:(.*?);/)![1];
  // Decode the Base64 string to binary data
  const bstr = atob(arr[1]);
  // Create an array of bytes
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  // Create a new File object using the byte array and the MIME type
  return new File([u8arr], fileName, { type: mime });
};

const detectOS = () => {
  const userAgent = window.navigator.userAgent;
  if (/Windows|win/i.test(userAgent)) {
    return IOperatingSystemEnums.WINDOWS;
  } else if (/mac|Macintosh|MacIntel|MacPPC|Mac68K|Mac OS/i.test(userAgent)) {
    return IOperatingSystemEnums.MAC_OS;
  } else if (/linux|Linux/i.test(userAgent)) {
    return IOperatingSystemEnums.LINUX;
  } else if (/android/i.test(userAgent)) {
    return IOperatingSystemEnums.ANDROID;
  } else if (/iphone|ipad|ipod/i.test(userAgent)) {
    return IOperatingSystemEnums.IOS;
  } else {
    return IOperatingSystemEnums.UNKNOWN_OS;
  }
};

const convertDateYYYYMMDD = (date: string | Date | Dayjs) =>
  dayjs(date).format("YYYY-MM-DD");

const downloadMedicalReport = (documentURL: string) => {
  fetch(documentURL)
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.blob();
    })
    .then((blob) => {
      const fileUrl = documentURL.split("/");
      const fileExtension = fileUrl[fileUrl.length - 1].split(".");
      const downloadLink = document.createElement("a");
      const url = URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = `prescription.${
        fileExtension[fileExtension.length - 1]
      }`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(downloadLink);
    })
    .catch((error) =>
      console.error("There was a problem downloading the PDF:", error)
    );
};

const convertDateDDMMM = (date: string) => dayjs(date).format("DD MMM");

const doctorProfileDropdownDispatchingEnums: any = {
  [IDoctorProfileDropdownEnums.PROFILE]: "isProfileOpen",
  [IDoctorProfileDropdownEnums.REVIEW]: "isReviewOpen",
  [IDoctorProfileDropdownEnums.AVAILABILITY]: "isAvailabilityOpen",
  [IDoctorProfileDropdownEnums.CLINICAL_TEAM]: "isClinicTeamOpen",
  [IDoctorProfileDropdownEnums.TEMPLATES]: "isTemplatesOpen",
  [IDoctorProfileDropdownEnums.CHANGE_HOSPITAL]: "isChangeHospitalOpen",
};

const dayFilterListing = [
  {
    label: "All",
    value: "All",
  },
  {
    label: "Mon",
    value: "Mon",
  },
  {
    label: "Tue",
    value: "Tue",
  },
  {
    label: "Wed",
    value: "Wed",
  },
  {
    label: "Thu",
    value: "Thu",
  },
  {
    label: "Fri",
    value: "Fri",
  },
  {
    label: "Sat",
    value: "Sat",
  },
  {
    label: "Sun",
    value: "Sun",
  },
];

const scheduleFilterList = [
  {
    label: "All",
    value: IScheduleAppointmentFilterEnum.ALL,
  },
  {
    label: "Upcoming",
    value: IScheduleAppointmentFilterEnum.UPCOMING,
  },
  {
    label: "Completed",
    value: IScheduleAppointmentFilterEnum.COMPLETED,
  },
  {
    label: "Cancelled",
    value: IScheduleAppointmentFilterEnum.CANCELLED,
  },
];

const languagesListInfo: IKeyPairsValues = {
  [ILanguageEnums.ENG]: "English",
  [ILanguageEnums.HIN]: "हिंदी",
  [ILanguageEnums.GUJ]: "ગુજરાતી",
  [ILanguageEnums.BEN]: "বাংলা",
};

const consultationTypes: IConsultationTypes = {
  [IDoctorScheduleConsultationType.IN_CLINIC]: "In-Clinic",
  [IDoctorScheduleConsultationType.VIDEO_CALL]: "Video Call",
};

const calculateDayFromTargetDate = (date: string, endDate?: string) => {
  const targetDate = dayjs(date);
  const today = endDate ? dayjs(endDate) : dayjs();
  const diffDays = today.diff(targetDate, "day");
  return diffDays === 0 && !endDate ? `Today` : `${diffDays} days`;
};

const transformDateToDDMMYYYY = (date: any) => {
  return dayjs(date).format("DD MMMM YYYY");
};

const ipDashboardListing = [
  {
    label: "ICU",
    value: IPDashboardFilterType.ICU,
    isSelection: true,
  },
  {
    label: "Ward",
    value: IPDashboardFilterType.WARD,
    isSelection: true,
  },
  {
    label: "Active",
    value: IPDashboardFilterType.ACTIVE,
    isSelection: false,
  },
  {
    label: "Discharge",
    value: IPDashboardFilterType.DISCHARGE,
    isSelection: false,
  },
];

const ipDashboardListingWeb = [
  {
    label: "Active",
    value: IPDashboardFilterType.ACTIVE,
  },
  {
    label: "Discharge",
    value: IPDashboardFilterType.DISCHARGE,
  },
];

const getTodayTomorrowDate = () => {
  const currentDate = dayjs();
  const nextDay = currentDate.add(1, "day");
  return {
    today: convertDateYYYYMMDD(currentDate),
    tomorrow: convertDateYYYYMMDD(nextDay),
  };
};

const admitPatientinitialState = {
  hospital_id: "",
  hospital_room_id: "",
  room_bed_id: "",
  sponsor_name: "",
  case_history: "",
  admission_date: null,
  hospital_uhid: "",
  name: "",
  member_id: "",
  age: "",
  gender: "",
  mobile_number: "",
};

const admitPatientDispatcher = (state: any, action: any) => {
  switch (action.type) {
    case IAdmitPatientType.SET_FIELD:
      return {
        ...state,
        [action.field]: action.value,
      };
    case IAdmitPatientType.SET_API_DATA:
      return {
        ...state,
        ...action.data,
      };
    default:
      return {};
  }
};

const getDay = (date: string) => {
  return dayjs(date).format("DD");
};

const removeAttributesAdmitPatientDetail = (
  patientDetail: IAdmittedPatientDetail
) => {
  const {
    id,
    doctor_id,
    patient_id,
    discharged_date,
    status,
    created_at,
    updated_at,
    number,
    room_available,
    room_name,
    type,
    phone_number,
    ...rest
  } = patientDetail;
  return { ...rest, mobile_number: phone_number };
};

const getGreeting = () => {
  const currentHour = dayjs().hour();
  if (currentHour < 12) {
    return "Good morning";
  } else if (currentHour < 18) {
    return "Good afternoon";
  } else {
    return "Good evening";
  }
};

const universalSearchList = [
  {
    label: "Appointments",
    value: IUniversalSearchEnum.APPOINTMENTS,
  },
  {
    label: "In Patients",
    value: IUniversalSearchEnum.IN_PATIENTS,
  },
  {
    label: "Clinical Team",
    value: IUniversalSearchEnum.CLINICAL_TEAM,
  },
];
const universalSearchType: IKeyPairsValues = {
  [IUniversalSearchEnum.APPOINTMENTS]: "appointments",
  [IUniversalSearchEnum.IN_PATIENTS]: "patients",
  [IUniversalSearchEnum.CLINICAL_TEAM]: "clinical teams",
};

const timeInAmPmFromDateTime = (value: string) => {
  return dayjs(value).format("hh:mm a");
};

const convertDDMMYYYYHHMM = (value: string) => {
  return dayjs(value).format("DD MMMM YYYY, hh:mm A");
};
const randomCardColorGenerator = () => {
  const colorsList = [
    "var(--warm-neutral-50)",
    "#FFF1EF",
    "#E7F8FF",
    "#FFF8DF",
    "#E8FFF7",
    "#EFFFE0",
    "#FFE0F1",
    "#F9FFE0",
    "#E4FDFF",
  ];
  return colorsList[Math.floor(Math.random() * colorsList.length)];
};

const getHealthAssessmentImageByType: IKeyPairsValues = {
  [IHealthAssesmentTypeEnums.Brain]: "BrainImage",
  [IHealthAssesmentTypeEnums.Spine]: "SpineImage",
  [IHealthAssesmentTypeEnums.HEAD]: "BrainImage",
};

const getLocation = () => {
  const location: any =
    storage.getSessionToken("location") &&
    JSON.parse(storage.getSessionToken("location") as any);
  return location;
};

export {
  getLocation,
  convertDDMMYYYYHHMM,
  getHealthAssessmentImageByType,
  randomCardColorGenerator,
  timeInAmPmFromDateTime,
  universalSearchType,
  universalSearchList,
  getGreeting,
  removeAttributesAdmitPatientDetail,
  getDay,
  admitPatientinitialState,
  admitPatientDispatcher,
  getTodayTomorrowDate,
  ipDashboardListingWeb,
  ipDashboardListing,
  transformDateToDDMMYYYY,
  calculateDayFromTargetDate,
  languagesListInfo,
  consultationTypes,
  scheduleFilterList,
  dayFilterListing,
  downloadMedicalReport,
  convertDateDDMMM,
  convertDateYYYYMMDD,
  detectOS,
  base64ToFile,
  transformDateToMMDDYYY,
  openClinicMapLocation,
  findDoctorFilterListing,
  getNextSixDates,
  getDateTime,
  languagesList,
  transformClasses,
  tranformIndexesToDays,
  convertDaysToRanges,
  timeInAmPmFormat,
  tranformDaysToIndexes,
  getAvailabilityListByDay,
  doctorSpeakLanguages,
  doctorDegreeName,
  doctorProfileDropdownDispatchingEnums,
};
