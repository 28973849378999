import { Grid } from "@mui/material";
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from "react";

import SearchSuggestions from "../../SearchSuggestions";
import { InputField } from "../../InputField";

import { IMedicineTest } from "../../../models/ApiRequestResponse/doctor";
import {
  IMedicineTestSuggestionData,
  ITitleInputValuesListEnums,
} from "../../../models/doctor";

import { searchMedicineTestApi } from "../../../utils/apis/doctor/prescription";
import useDebounce from "../../../utils/hooks/useDebouncer";

interface IProps {
  type: ITitleInputValuesListEnums;
  label: string;
  getMedicineTestDetailHandler?: (
    values: IMedicineTestSuggestionData,
    type: ITitleInputValuesListEnums
  ) => void;
  getMedicineTestDetailEnterEventHandler?: (
    values: IMedicineTestSuggestionData,
    type: ITitleInputValuesListEnums
  ) => void;
}

const initialState: IMedicineTestSuggestionData = {
  testId: "0",
  name: "",
  medicineId: "0",
};

const addMedicineTestValues = (state: any, action: any) => {
  switch (action.type) {
    case ITitleInputValuesListEnums.TEST:
    case ITitleInputValuesListEnums.MEDICINE:
      return {
        ...state,
        [action.field]: action.value,
      };
    default:
      return state;
  }
};

const MedicineTestSuggestions: React.FC<IProps> = (props) => {
  const {
    label,
    type,
    getMedicineTestDetailHandler,
    getMedicineTestDetailEnterEventHandler,
  } = props;
  const [findMedicineTest, setFindMedicineTest] = useState<IMedicineTest[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [selectSearchItem, setSelectSearchItem] = useState<string>("");
  const [state, dispatch] = useReducer(addMedicineTestValues, initialState);

  const debounceSearchMedicineTest = useDebounce(searchText, 500);

  const searchMedicineTest = async () => {
    const searchMedicineTestResponse = await searchMedicineTestApi(
      type,
      searchText
    );
    if ("result" in searchMedicineTestResponse) {
      setFindMedicineTest(searchMedicineTestResponse?.result);
    }
  };

  const clearSearchMedicineTest = () => {
    setFindMedicineTest([]);
    setSelectSearchItem("");
  };

  const resetMedicineTestId = useCallback(() => {
    const isSearchFound = findMedicineTest.some((list, index) =>
      list.name.includes(searchText)
    );
    if (!isSearchFound) {
      dispatch({
        type: type,
        field:
          type === ITitleInputValuesListEnums.TEST ? "testId" : "medicineId",
        value: "0",
      });
    }
    clearSearchMedicineTest();
  }, [findMedicineTest]);

  const getMedicineTestEnteredDetailHandler = (event: any) => {
    if (event.key === "Enter") {
      getMedicineTestDetailEnterEventHandler &&
        getMedicineTestDetailEnterEventHandler(state, type);
    }
  };

  useEffect(() => {
    if (!debounceSearchMedicineTest?.length) {
      clearSearchMedicineTest();
    } else {
      dispatch({
        type: type,
        field: "name",
        value: searchText,
      });
      if (selectSearchItem?.length === 0) {
        searchMedicineTest();
      }
      resetMedicineTestId();
    }
  }, [debounceSearchMedicineTest]);

  useEffect(() => {
    if (selectSearchItem?.length > 0) {
      const { name } =
        findMedicineTest.find((list) => list.id === selectSearchItem) || {};
      setSearchText(name!);
      dispatch({
        type: type,
        field: "name",
        value: name,
      });
      dispatch({
        type: type,
        field:
          type === ITitleInputValuesListEnums.TEST ? "testId" : "medicineId",
        value: selectSearchItem?.length === 0 ? "0" : selectSearchItem,
      });
    }
  }, [selectSearchItem]);

  useEffect(() => {
    if (getMedicineTestDetailHandler) {
      setTimeout(() => getMedicineTestDetailHandler(state, type), 300);
    }
  }, [state]);
  return (
    <Grid item>
      <InputField
        value={searchText}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          setSearchText(event.target.value)
        }
        onKeyDown={getMedicineTestEnteredDetailHandler}
        label={label}
        placeholder={label}
        fullWidth
      />
      {findMedicineTest.length > 0 && (
        <SearchSuggestions
          typeOfSearch={type}
          clearSearchSuggestions={clearSearchMedicineTest}
          foundDataList={findMedicineTest.map((record, index) => ({
            label: record.name,
            value: record.id,
          }))}
          seachingText={searchText}
          selectSearchItem={selectSearchItem}
          setSelectSearchItem={setSelectSearchItem}
        />
      )}
    </Grid>
  );
};

export default MedicineTestSuggestions;
