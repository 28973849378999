// src/i18n/i18n.ts
import { createContext, useContext, useState } from "react";
import { IntlProvider } from "react-intl";
import enMessages from "./locales/eng.json";
import hiMessages from "./locales/hin.json";
import bnMessages from "./locales/ben.json";
import guMessages from "./locales/guj.json";
import { ILanguageEnums } from "../models/onboarding";

// Define a type for supported languages
type SupportedLanguages =
  | ILanguageEnums.ENG
  | ILanguageEnums.HIN
  | ILanguageEnums.BEN
  | ILanguageEnums.GUJ;

const messages = {
  eng: enMessages,
  hin: hiMessages,
  ben: bnMessages,
  guj: guMessages,
};

const LanguageContext = createContext<{
  language: SupportedLanguages;
  changeLanguage: (lang: SupportedLanguages) => void;
}>({
  language: ILanguageEnums.ENG, // Default language
  changeLanguage: () => {},
});

export const LanguageProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [language, setLanguage] = useState<SupportedLanguages>(
    (localStorage.getItem("lang") as SupportedLanguages) || ILanguageEnums.ENG
  );

  const changeLanguage = (lang: string) => {
    setLanguage(lang as SupportedLanguages);
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      <IntlProvider locale={language} messages={messages[language]}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
