import { styled, TextField, TextFieldProps } from "@mui/material";

const InputField = styled(TextField)<
  TextFieldProps & { backgroundColor?: string }
>(({ backgroundColor }) => {
  return {
    "& .MuiInputBase-input.Mui-disabled": {
      color: "black",
      "-webkit-text-fill-color": "black",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--neutral-100)",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-disabled": {
        "& fieldset": {
          borderColor: "white",
        },
      },
      "&:hover fieldset": {
        borderColor: "var(--neutral-100)",
        borderRadius: "0.8rem",
        borderBottom: "0.1rem solid var(--gray-200)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "var(--neutral-100)",
        borderRadius: "0.8rem",
        borderBottom: "0.1rem solid var(--gray-200)",
      },
      backgroundColor: backgroundColor ?? "white",
      borderRadius: "0.8rem",
    },
    "&.Mui-disabled": {
      "& .MuiOutlinedInput-root": {
        borderColor: "white",
      },
    },
    "& .MuiInputLabel-root": {
      color: "var(--gray-400)",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "var(--gray-400)",
    },
  };
});

export { InputField };
