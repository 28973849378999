import React from "react";
import { Grid } from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { ITitleInputValuesListEnums } from "../../../models/doctor";
import {DIRECTION} from "../../../enums/common";

import style from "./index.module.css";

interface IProps {
  identifier: ITitleInputValuesListEnums;
  name: string;
  removeItemHandler: (
    identifier: ITitleInputValuesListEnums,
    name: string
  ) => void;
  mealType?: string;
  frequency?: string;
  note: string;
}
const MedicineTestListRemoveManager: React.FC<IProps> = (props) => {
  const { identifier, name, note, frequency, mealType, removeItemHandler } =
    props;
  return (
    <Grid container direction={mealType?DIRECTION.ROW:DIRECTION.COLUMN} className={mealType?style.medicineListContainer:style.testListContainer}>
      <Grid container direction={DIRECTION.ROW} className={style.medicineTestNameContainer}>
        <p  className={style.medicineTestName}>
          {name}
        </p>
      
        
        <p          
            className={mealType?style.removeMedicinBar:style.removeTestbar}
            onClick={() => removeItemHandler(identifier, name)}
          >
            <DeleteForeverIcon/>
          </p>
        
      
      </Grid>
      {identifier === ITitleInputValuesListEnums.MEDICINE && (
        <Grid className={style.medicineTestDoseContainer} >
          <p  className={style.mealTime}>
            {" "}
            {mealType}
          </p>
          <p  className={style.mealDays}>
            {frequency}
          </p>
        </Grid>
      )}
      {note && (
        <Grid item className={mealType?style.noteContainer:style.testnoteContainer}>
          <p  className={style.note}>
            {note}
          </p>         
        </Grid>       
      )}
      {mealType && (
        <Grid>
        <p          
            className={style.removeMedicineTest}
            onClick={() => removeItemHandler(identifier, name)}
          >
            <DeleteForeverIcon/>
          </p>
      </Grid>

      )}
      
    </Grid>
    
  );
};

export default MedicineTestListRemoveManager;
