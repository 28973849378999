import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { GBSCError } from "../../../models/GBSCError";
import {
  IAdmittedPatientDetail,
  IDoctorHospitalDetail,
  IDoctorHospitalListResponse,
  IHospitalPatient,
  IHospitalRooms,
} from "../../../models/ApiRequestResponse/doctor";
import { IAdmitPatientPopUps, IRoomType } from "../../../models/doctor";
import { IPDashboardSliceType } from "./models/ipDashboardSliceModel";

import {
  getAdmittedPatientDetailApi,
  getDoctorHospitalListApi,
  getDoctorHospitalPatientListApi,
  getHospitalRoomsListApi,
} from "../../../utils/apis/doctor/ipDashboard";

interface IDoctorHospitalPatients {
  hospitalId: string;
  discharged?: boolean;
  start_date?: string;
  end_date?: string;
  room_id?: string;
  room_bed_id?: string;
  keyword?: string;
}
export const fetchDoctorHospitalListing = createAsyncThunk<
  IDoctorHospitalListResponse | GBSCError
>("doctor/fetchDoctorHospitalListing", async () => {
  const response = await getDoctorHospitalListApi();
  return response;
});

export const fetchDoctorHospitalPatientListing = createAsyncThunk(
  "doctor/fetchDoctorHospitalPatientListing",
  async ({
    hospitalId,
    start_date,
    end_date,
    discharged,
    keyword,
    room_id,
    room_bed_id,
  }: IDoctorHospitalPatients) => {
    const response = await getDoctorHospitalPatientListApi(
      hospitalId,
      start_date,
      end_date,
      discharged,
      room_id,
      room_bed_id,
      keyword
    );
    return response;
  }
);

export const fetchHospitalRoomsListing = createAsyncThunk(
  "doctor/fetchHospitalRoomsListing",
  async ({
    hospitalId,
    available,
  }: {
    hospitalId: string;
    available?: boolean;
  }) => {
    const response = await getHospitalRoomsListApi(hospitalId, available);
    return response;
  }
);

export const fetchAdmitPatientDetails = createAsyncThunk(
  "doctor/fetchAdmitPatientDetails",
  async ({ admitPatientId }: { admitPatientId: string }) => {
    const response = await getAdmittedPatientDetailApi(admitPatientId);
    return response;
  }
);

const ipDashboardSlice = createSlice({
  name: "ipDashboard",
  initialState: {
    hospitals: [] as IDoctorHospitalDetail[],
    hospitalPatients: [] as IHospitalPatient[],
    patientDetail: {} as IAdmittedPatientDetail,
    rooms: {
      allRooms: [] as IHospitalRooms[],
      ward: [] as IHospitalRooms[],
      icu: [] as IHospitalRooms[],
    },
    admitPatientPopUps: {
      isAdmitted: false,
      isDischarged: false,
    } as IAdmitPatientPopUps,
    isEditAdmitPatient: false as boolean,
    isReAdmitPatient: false as boolean,
    selectedHospitalId: "",
    isLoading: true as boolean,
    isRoomLoading: true as boolean,
    selectRoomId: "" as string,
    selectedDayText: "" as string,
  } as IPDashboardSliceType,
  reducers: {
    setSelectedHospitalId: (state, action) => {
      state.selectedHospitalId = action.payload;
    },
    setEditAdmitPatient: (state, action) => {
      state.isEditAdmitPatient = action.payload;
    },
    setReAdmitPatient: (state, action) => {
      state.isReAdmitPatient = action.payload;
    },
    resetAdmitPatientDetail: (state) => {
      state.patientDetail = {} as IAdmittedPatientDetail;
    },
    setOpenCloseAdmittedDischargeModal: (
      state: IPDashboardSliceType,
      action
    ) => {
      const key: keyof IAdmitPatientPopUps = action.payload;
      state.admitPatientPopUps = {
        ...state.admitPatientPopUps,
        [action.payload]: !state.admitPatientPopUps[key],
      };
    },
    resetAdmitPatients: (state) => {
      state.hospitalPatients = [] as IHospitalPatient[];
    },
    setSelectRoomId: (state, action) => {
      state.selectRoomId = action.payload;
    },
    setSelectedDayText: (state, action) => {
      state.selectedDayText = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // doctor hospital listing
      .addCase(fetchDoctorHospitalListing.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDoctorHospitalListing.fulfilled, (state, action) => {
        state.isLoading = false;
        if ("result" in action.payload) {
          state.hospitals = action.payload.result;
          state.selectedHospitalId = action.payload.result[0]?.hospital_id;
        }
      })
      .addCase(fetchDoctorHospitalListing.rejected, (state) => {
        state.isLoading = false;
      })
      //  doctor hospital patient listing
      .addCase(fetchDoctorHospitalPatientListing.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDoctorHospitalPatientListing.fulfilled, (state, action) => {
        state.isLoading = false;
        if ("result" in action.payload) {
          state.hospitalPatients = action.payload.result;
        }
      })
      .addCase(fetchDoctorHospitalPatientListing.rejected, (state) => {
        state.isLoading = false;
      })
      //  hospital room listing
      .addCase(fetchHospitalRoomsListing.pending, (state) => {
        state.isRoomLoading = true;
      })
      .addCase(fetchHospitalRoomsListing.fulfilled, (state, action) => {
        state.isRoomLoading = false;
        if ("result" in action.payload) {
          state.rooms.allRooms = action.payload.result;
          state.rooms.icu = action.payload.result.filter(
            (room, index) => room.type === IRoomType.ICU
          );
          state.rooms.ward = action.payload.result.filter(
            (room, index) => room.type === IRoomType.WARD
          );
        }
      })
      .addCase(fetchHospitalRoomsListing.rejected, (state) => {
        state.isRoomLoading = false;
      })
      //  admit patient detail
      .addCase(fetchAdmitPatientDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAdmitPatientDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        if ("result" in action.payload) {
          state.patientDetail = action.payload.result;
        }
      })
      .addCase(fetchAdmitPatientDetails.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const {
  setSelectedDayText,
  setSelectRoomId,
  resetAdmitPatients,
  setSelectedHospitalId,
  setEditAdmitPatient,
  setReAdmitPatient,
  resetAdmitPatientDetail,
  setOpenCloseAdmittedDischargeModal,
} = ipDashboardSlice.actions;
export default ipDashboardSlice.reducer;
