import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import EnterMobile from "../../../components/Verification/EnterMobile";
import VerifyOTP from "../../../components/Verification/VerifyOTP";

import { IMobileVerify, IOtpVerify } from "../../../models/onboarding";
import { IRoleType } from "../../../models/role";
import { IAddPatient } from "../../../models/doctor";

import {
  mobileVerifyOrSendOTPApi,
  OTPVerifyApi,
} from "../../../utils/apis/onboarding/OnBoardingApis";
import { countryCode } from "../../../utils/text";
import { storage } from "../../../utils/Storage";
import useIsMobile from "../../../utils/hooks/useIsMobile";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  setAddPatient,
  setIsLocationAccess,
} from "../../../store/slices/doctor/topNavigation";

const MobileOTPVerify: React.FC = () => {
  const [isMobileVerifyShow, setIsMobileVerifyShow] = useState<boolean | null>(
    true
  );
  const [mobileNoOTPId, setMobileNoOTPId] = useState({
    mobileNo: "",
    otpId: "",
  });

  const navigate = useNavigate();

  const { mobileCheck } = useAppSelector(
    (state) => state.topNavigation.addPatient
  );
  const { fcmDeviceToken } = useAppSelector((state) => state.videoAppointment);

  const dispatch = useAppDispatch();
  const { isMobile } = useIsMobile();

  const mobileSubmitHandler = async (values: IMobileVerify) => {
    try {
      const mobileVerifyRes = await mobileVerifyOrSendOTPApi({
        country_code: countryCode,
        device_id: "string",
        device_token: fcmDeviceToken ?? "string",
        phone_number: values.mobileNo,
      });
      if ("result" in mobileVerifyRes) {
        setMobileNoOTPId({
          mobileNo: values.mobileNo,
          otpId: mobileVerifyRes?.result?.OtpId,
        });
        setIsMobileVerifyShow(!isMobileVerifyShow);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const mobileCheckOpenClosed = (value: boolean) => {
    dispatch(
      setAddPatient({
        field: IAddPatient.MOBILE_CHECK,
        value,
      })
    );
  };

  const handleOTPSubmit = async (values: IOtpVerify) => {
    try {
      const otpVerifyRes = await OTPVerifyApi({
        device_id: "string",
        device_token: fcmDeviceToken ?? "string",
        otp_code: values.otp,
        token: mobileNoOTPId?.otpId,
      });
      if ("result" in otpVerifyRes) {
        storage.setSessionToken("token", otpVerifyRes.result.token);
        storage.setSessionToken("user_id", otpVerifyRes.result.user_id);
        storage.setSessionToken("roleType", otpVerifyRes.result.role);
        storage.setSessionToken("name", otpVerifyRes.result.name);
        if (otpVerifyRes.result.role === IRoleType.DOCTOR) {
          navigate("/schedule");
        } else {
          dispatch(setIsLocationAccess(true));
          setIsMobileVerifyShow(null);
        }
        mobileCheckOpenClosed(false);
      }
    } catch (error) {
      console.log(error);
      mobileCheckOpenClosed(false);
    }
  };

  const resendOTP = async () => {
    try {
      const mobileVerifyRes = await mobileVerifyOrSendOTPApi({
        country_code: countryCode,
        device_id: "string",
        device_token: "string",
        phone_number: mobileNoOTPId?.mobileNo,
      });
      if ("result" in mobileVerifyRes) {
        setMobileNoOTPId({
          ...mobileNoOTPId,
          otpId: mobileVerifyRes.result.OtpId,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const navigateToMobileVerify = () =>
    setIsMobileVerifyShow(!isMobileVerifyShow);

  const mobileCheckCloseHandler = () => {
    !isMobile
      ? (setIsMobileVerifyShow(null), mobileCheckOpenClosed(false))
      : {};
  };

  useEffect(() => {
    if (mobileCheck) setIsMobileVerifyShow(mobileCheck);
  }, [mobileCheck]);
  return (
    <>
      {isMobileVerifyShow ? (
        <EnterMobile
          isOpen={isMobileVerifyShow}
          closeHandler={mobileCheckCloseHandler}
          heading="loginUsingPhoneText"
          mobileSubmitHandler={mobileSubmitHandler}
          btnLabel="getOTP"
        />
      ) : (
        isMobileVerifyShow !== null && (
          <VerifyOTP
            handleOTPSubmit={handleOTPSubmit}
            mobileNo={mobileNoOTPId?.mobileNo}
            navigateToMobileVerify={navigateToMobileVerify}
            resendOTP={resendOTP}
          />
        )
      )}
    </>
  );
};

export default MobileOTPVerify;
