import React from "react";
import { Dialog as DialogMUI, Grid, styled } from "@mui/material";

import GetImages from "../GetImages";

import style from "./index.module.css";

const CustomDialog = styled(DialogMUI)(() => ({
  "& .MuiDialog-paper": {
    borderRadius: "1.2rem",
    textAlign: "center",
    maxWidth: "118.2rem",
    minWidth: "39rem",
    backgroundColor: "var(--gray-50)",
    "@media (max-width: 60rem)": {
      minWidth: "30rem",
      maxWidth: "100%",
      borderRadius: "0.8rem",
    },
  },
}));

interface IProps {
  children: React.ReactNode;
  isOpen?: boolean;
  closeHandler?: () => void;
  isCloseIcon?: boolean;
}
const Dialog: React.FC<IProps> = (props) => {
  const { children, isOpen = true, closeHandler, isCloseIcon = false } = props;

  return (
    <CustomDialog open={isOpen} onClose={closeHandler}>
      {isCloseIcon && (
        <Grid item className={style.closeIconContainer} onClick={closeHandler}>
          <GetImages name="CloseIcon" width="14" height="14" />
        </Grid>
      )}
      {children}
    </CustomDialog>
  );
};

export default Dialog;
