import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import style from "./index.module.css";

import Modal from "../Modal";
import { InputField } from "../InputField";
import Button from "../Button";
import Dialog from "../Dialog";

import { IButtonType } from "../../models/button";
import { IMobileVerify } from "../../models/onboarding";

import { countryCode } from "../../utils/text";
import useIsMobile from "../../utils/hooks/useIsMobile";
import { FormattedMessage, useIntl } from "react-intl";
interface IProps {
  heading: string;
  mobileSubmitHandler: (values: IMobileVerify) => void;
  btnLabel?: string;
  showCountryCode?: boolean;
  showHeaderNavigation?: boolean;
  headerNavigationText?: string;
  isOpen: boolean;
  closeHandler: () => void;
  mobileNo?: string;
}

const EnterMobile: React.FC<IProps> = (props) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<IMobileVerify>();

  const {
    heading,
    mobileSubmitHandler,
    btnLabel = "getOTP",
    showCountryCode = true,
    showHeaderNavigation = false,
    headerNavigationText,
    isOpen,
    closeHandler,
    mobileNo,
  } = props;

  const { isMobile } = useIsMobile();
  const intl = useIntl();

  const mobileInputField = () => {
    return (
      <>
        {showHeaderNavigation && (
          <Grid
            item
            className={style.headerBackNav}
            onClick={() => closeHandler && closeHandler()}
          >
            <ArrowBackIosNewIcon fontSize={"inherit"} />{" "}
            <Typography component={"h3"} className={style.heading}>
              <FormattedMessage
                id={headerNavigationText}
                defaultMessage="headerNavigationText"
              />
            </Typography>
          </Grid>
        )}
        <Grid item className={style.headerContainer}>
          <Typography component={"h3"} className={style.heading}>
            <FormattedMessage
              id={heading}
              defaultMessage="Login using Phone Number"
            />
          </Typography>
        </Grid>
        <form onSubmit={handleSubmit(mobileSubmitHandler)}>
          <Grid item>
            <Grid item className={style.mobileBox}>
              <Grid item className={style.mobileInputContainer}>
                {showCountryCode && (
                  <InputField
                    className={style.countryCodeInput}
                    label={intl.formatMessage({
                      id: "code",
                      defaultMessage: "Code",
                    })}
                    variant="outlined"
                    placeholder={intl.formatMessage({
                      id: "code",
                      defaultMessage: "Code",
                    })}
                    value={countryCode}
                    disabled
                  />
                )}
                <InputField
                  type="number"
                  {...register("mobileNo", {
                    required: intl.formatMessage({
                      id: "mobileNumberReq",
                      defaultMessage: "Mobile Number is required",
                    }),
                    validate: (value) =>
                      value.length === 10 ||
                      intl.formatMessage({
                        id: "mobileNumberValidation",
                        defaultMessage: "Mobile Number should have 10 digits",
                      }),
                  })}
                  label={intl.formatMessage({
                    id: "mobileNumberText",
                    defaultMessage: "Mobile Number",
                  })}
                  variant="outlined"
                  placeholder={intl.formatMessage({
                    id: "mobileNumberText",
                    defaultMessage: "Mobile Number",
                  })}
                  fullWidth
                />
              </Grid>
              <Grid item className={style.mobileVerifyErrorContainer}>
                {errors.mobileNo && (
                  <Typography component={"span"} className={style.errorMessage}>
                    {errors.mobileNo.message}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid item className={style.buttonContainer}>
              <Button type={IButtonType.SUBMIT}>
                <FormattedMessage id={btnLabel} defaultMessage="Get OTP" />
              </Button>
            </Grid>
          </Grid>
        </form>
      </>
    );
  };

  useEffect(() => {
    if (mobileNo) {
      setValue("mobileNo", mobileNo);
    }
  }, [mobileNo]);
  return (
    <>
      {isMobile ? (
        <Modal
          isOpen={isOpen}
          closeHandler={closeHandler}
          lineBottomBackground="white"
        >
          {mobileInputField()}
        </Modal>
      ) : (
        <Dialog isOpen={isOpen} closeHandler={closeHandler}>
          {mobileInputField()}
        </Dialog>
      )}
    </>
  );
};

export default EnterMobile;
