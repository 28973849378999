import {
  IHealthAssesmentQuestionResponse,
  IHealthAssessmentQuestionSubmissionRequest,
  IHealthAssessmentQuestionSubmissionResponse,
  IHealthAssessmentResponse,
} from "../../../models/ApiRequestResponse/patient";
import { GBSCError } from "../../../models/GBSCError";

import { get, post } from "../../TypedApi";
import {
  APIEndpoint,
  IAPIStatusCode,
  IAPIStatusType,
  messages,
} from "../APIEndpointConfig";

export const getHealthAssessmentListApi = async (
  type: string
): Promise<IHealthAssessmentResponse | GBSCError> => {
  try {
    const response = await get<IHealthAssessmentResponse>(
      `${APIEndpoint.patient.healthAssessmentList}${type}`
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }
  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const getHealthAssessmentQuestionApi = async (
  healthAssessmentId: string
): Promise<IHealthAssesmentQuestionResponse | GBSCError> => {
  try {
    const response = await get<IHealthAssesmentQuestionResponse>(
      `${APIEndpoint.patient.healthAssessmentQuestionList}${healthAssessmentId}`
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }
  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const postHealthAssessmentQuestionSubmissionApi = async (
  requestBody: IHealthAssessmentQuestionSubmissionRequest
): Promise<IHealthAssessmentQuestionSubmissionResponse | GBSCError> => {
  try {
    const response = await post<IHealthAssessmentQuestionSubmissionResponse>(
      APIEndpoint.patient.healthAssessmentResult,
      requestBody
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }
  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};
