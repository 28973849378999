import React from "react";
import { Grid, Typography } from "@mui/material";

import style from "./index.module.css";

import CopyWriteFooter from "../CopyWriteFooter";
import GetImages from "../GetImages";
import Button from "../Button";
import DownloadAppBanner from "../DownloadAppBanner";

import { IButtonVariant } from "../../models/button";
import { FormattedMessage } from "react-intl";

const secondColumnLinks = [
  {
    title: "company",
    items: [
      "patient_side",
      "healthy_individual",
      "about_us",
      "our_app",
      "careers",
      "contact",
    ],
  },
  {
    title: "terms_utility",
    items: [
      "cookies_policy",
      "terms_and_conditions",
      "privacyPolicyText",
      "licenses",
    ],
  },
];

const thirdColumnLinks = [
  {
    title: "patient",
    items: ["treatment", "success_stories"],
  },
  {
    title: "health_hub",
    items: ["exercise_videos", "health_tips"],
  },
  {
    title: "faq",
    items: [],
  },
];

const fourthColumnLinks = [
  {
    title: "featured",
    items: [
      "appointment_booking",
      "second_opinion",
      "find_doctor",
      "free_MRI_CT_review",
      "medicine_delivery",
      "international_patient",
      "mental_and_brain_check",
      "spine_health_check",
    ],
  },
];

const socialIconsList = [
  "InstagramIcon",
  "FbIcon",
  "YouTubeIcon",
  "LinkedInIcon",
];

const BottomNavigation: React.FC = () => {
  return (
    <Grid item>
      <DownloadAppBanner />
      <Grid item className={style.bottomNavigationContainer}>
        <Grid item className={style.gbscAboutContainer}>
          <Grid item>
            <GetImages name="GBSCLargeLogo" width="120" height="70" />
          </Grid>
          <Grid item className={style.gbscAboutText}>
            <Typography component={"p"}>
              <FormattedMessage
                id="download_description"
                defaultMessage="Download description"
              />
            </Typography>
          </Grid>
        </Grid>

        <Grid item>
          {secondColumnLinks.map((navItem, index) => (
            <Grid item key={index}>
              <Typography component={"p"} className={style.linkTitle}>
                <FormattedMessage id={navItem.title} defaultMessage="Title" />
              </Typography>
              {navItem.items.map((item, index) => (
                <Typography
                  component={"p"}
                  key={index}
                  className={style.linkText}
                >
                  <FormattedMessage id={item} defaultMessage="Items" />
                </Typography>
              ))}
            </Grid>
          ))}
        </Grid>
        <Grid item>
          {thirdColumnLinks.map((navItem, index) => (
            <Grid item key={index}>
              <Typography component={"p"} className={style.linkTitle}>
                <FormattedMessage id={navItem.title} defaultMessage="Title" />
              </Typography>
              {navItem.items.map((item, index) => (
                <Typography
                  component={"p"}
                  key={index}
                  className={style.linkText}
                >
                  <FormattedMessage id={item} defaultMessage="Items" />
                </Typography>
              ))}
            </Grid>
          ))}
        </Grid>
        <Grid item>
          {fourthColumnLinks.map((navItem, index) => (
            <Grid item key={index}>
              <Typography component={"p"} className={style.linkTitle}>
                <FormattedMessage id={navItem.title} defaultMessage="Title" />
              </Typography>
              {navItem.items.map((item, index) => (
                <Typography
                  component={"p"}
                  key={index}
                  className={style.linkText}
                >
                  <FormattedMessage id={item} defaultMessage="Items" />
                </Typography>
              ))}
            </Grid>
          ))}
        </Grid>
        <Grid item>
          <Grid item className={style.downloadAppBtnContainer}>
            <Button variant={IButtonVariant.WHITE}>
              <GetImages name="MobileIcon" width="28" height="24" />
              <FormattedMessage
                id="download_app"
                defaultMessage="Download the app"
              />
            </Button>
          </Grid>
          <Grid item className={style.customerSupportContainer}>
            <Typography component={"p"}>
              <FormattedMessage
                id="customer_support"
                defaultMessage="Customer Support"
              />
            </Typography>
            <Grid item className={style.telePhoneNumber}>
              <GetImages name="TelePhoneIcon" width="24" height="20" />
              <Typography component={"p"}>+91 - 9876543210</Typography>
            </Grid>
            <Grid item className={style.timingContactEmailContainer}>
              <Typography component={"p"}>10am - 8pm (Mon-Sat)</Typography>
              <Grid item className={style.emailContainer}>
                <GetImages name="MessageOrangeIcon" width="32" height="20" />
                <Typography component={"p"}>hello@brainandspine.com</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item className={style.socialAccountsContainer}>
            <Typography component={"p"}>
              <FormattedMessage
                id="connect_with_us"
                defaultMessage="Connect with us on"
              />
            </Typography>
            <Grid item className={style.socialLinks}>
              {socialIconsList.map((icon, index) => (
                <Grid item key={index} className={style.iconOuterContainer}>
                  <GetImages name={icon} width="20" height="20" />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <CopyWriteFooter />
    </Grid>
  );
};

export default BottomNavigation;
