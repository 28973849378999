import { IStatusCodeMessage } from ".";

export interface IMobileVerifyOrSendOTPReq {
  phone_number: string;
  country_code: string;
  device_id: string;
  device_token: string;
}

export interface IMobileVerifyOrSend {
  OtpId: string;
}

export interface IMobileVerifyOrSendOTPInfo {
  result: IMobileVerifyOrSend;
}

export type IMobileVerifyOrSendOTPRes = IStatusCodeMessage &
  IMobileVerifyOrSendOTPInfo;

export interface IOTPVerifyReq {
  otp_code: string;
  token: string;
  device_id: string;
  device_token: string;
}

export enum IModalKeyEnums {
  APPOINTMENT = "appointment",
  AVAILABILITY_SETTING = "availabilitySetting",
  doctor = "doctor",
  MEDICAL_RECORD = "medicalRecord",
  MEDICINE = "medicine",
  PATIENT_MEMBER = "patientMember",
  PRESCRIPTION = "prescription",
  PRESCRIPTION_ATTRIBUTES = "prescriptionAttributes",
  PRESCRIPTION_TEMPLATE = "prescriptionTemplate",
  REVIEW = "review",
  SCHEDULE_SLOTS = "scheduleSlots",
  TEST = "test",
  UPLOAD_DOCUMENT = "uploadDocument",
  USER = "user",
}
export interface IFeature {
  featureKey: string;
  permissionAccess: number;
}

export interface IPermission {
  moduleKey: IModalKeyEnums;
  features: IFeature[];
}

export interface IOTPVerifyInfo {
  token: string;
  user_id: string;
  role: string;
  language: string;
  name: string;
  permissions: IPermission[];
}
export interface IOTPVerifyDetail {
  result: IOTPVerifyInfo;
}

export type IOTPVerifyRes = IStatusCodeMessage & IOTPVerifyDetail;

export interface IMobileVerify {
  mobileNo: string;
}

export interface IOtpVerify {
  otp: string;
}

export interface IAccessTokenDetail {
  country_code: string;
  phone_number: string;
  user_id: string;
  role: string;
  language: string;
}
export interface IAccessTokenResult {
  result: IAccessTokenDetail;
}

export type IAccessToken = IStatusCodeMessage & IAccessTokenResult;

export enum ILanguageEnums {
  ENG = "eng",
  HIN = "hin",
  GUJ = "guj",
  BEN = "ben",
}
