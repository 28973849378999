import React from "react";
import { CircularProgress, Grid } from "@mui/material";

interface IProps {
  height?: string;
}
const Loader: React.FC<IProps> = (props) => {
  const { height = "100vh" } = props;
  return (
    <Grid
      item
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height,
      }}
    >
      <CircularProgress sx={{ color: "var(--orange-500)" }} />
    </Grid>
  );
};

export default Loader;
