import { lazy } from "react";

const Onboarding = lazy(
  () => import("../../views/onboarding/Onboarding")
);

const OnboardingRoutes = [
  {
    path: "/",
    component: <Onboarding />,
  },
];

export default OnboardingRoutes;
