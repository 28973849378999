import React, { useState } from "react";
import { Grid, Menu, MenuItem, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import GetImages from "../../../GetImages";
import Button from "../../../Button";
import MobileOTPVerify from "../../../../views/onboarding/MobileOTPVerify";

import style from "./index.module.css";

import PatientImage from "../../../../assets/patientProfile.png";

import { storage } from "../../../../utils/Storage";

import {
  IAddPatient,
  IPatientExistOrNotModule,
} from "../../../../models/doctor";

import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { setAddPatient } from "../../../../store/slices/doctor/topNavigation";
import { setModuleName } from "../../../../store/slices/doctor/checkMobilePatientExistOrNot";
import { FormattedMessage, useIntl } from "react-intl";
import { IButtonVariant } from "../../../../models/button";
import { languagesList } from "../../../../utils";

const PatientTopNavigation: React.FC = () => {
  const token = storage.getSessionToken("token");
  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    (localStorage.getItem("lang") as string) || languagesList[0].value
  );

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { addPatient } = useAppSelector((state) => state.topNavigation);
  const { moduleName } = useAppSelector((state) => state.patientExistOrNot);
  const patientNavItems = [
    {
      path: "/home",
      label: "home",
    },
    {
      path: "/find-doctor",
      label: "doctors",
    },
    {
      path: "/medicines",
      label: "medicines",
    },
    {
      path: "/test-reports",
      label: "tests",
    },
    {
      path: "/health-tips",
      label: "health",
    },
  ];

  const handleSelected = (value: string) => {
    setSelectedLanguage(value);
    storage.setSessionToken("lang", value);
    handleClose();
    window.location.reload();
  };

  const selectedLangLabel = languagesList
    ?.filter((data) => data.value === selectedLanguage)
    .map((lang) => lang.label);

  return (
    <Grid item className={style.container}>
      <Grid item>
        <GetImages name="GBSCLogo" width="84" height="51" />
      </Grid>
      <Grid item className={style.searchInputLocationContainer}>
        <Grid item className={style.locationNameInputContainer}>
          <GetImages name="LocationAccessSmallIcon" width="24" height="20" />
          <Typography component={"p"}>Durgapur</Typography>
        </Grid>
        <input
          placeholder={intl.formatMessage({
            id: "topBarSearchPlaceholder",
            defaultMessage: "Search doctors, clinics, tests here",
          })}
        />
        <Grid item className={style.searchIcon}>
          <GetImages name="NavSearchIcon" width="24" height="24" />
        </Grid>
      </Grid>
      <Grid item className={style.navItemsContainer}>
        {patientNavItems.map((nav, index) => (
          <Grid
            item
            key={index}
            className={style.navItemLabel}
            onClick={() => navigate(nav.path)}
          >
            <Typography component={"p"}>
              <FormattedMessage id={nav.label} defaultMessage="Nav Item" />
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Grid item>
        <Button
          variant={IButtonVariant.WHITE}
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          btnTrigger={handleClick}
        >
          <GetImages name="WorldIcon" width="24" height="20" />
          <Typography component={"p"}>{selectedLangLabel}</Typography>
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {languagesList.length > 0 &&
            languagesList.map((data) => (
              <MenuItem
                key={data.value}
                selected={data.value === selectedLanguage}
                onClick={() => handleSelected(data.value)}
              >
                {data.label}
              </MenuItem>
            ))}
        </Menu>
      </Grid>
      <Grid item className={style.telephoneEmergencyContainer}>
        <Grid item className={style.telephoneContainer}>
          <GetImages name="TelePhoneIcon" width="24" height="20" />
          <Typography component={"p"}>9721 108 108</Typography>
        </Grid>
        <Grid item className={style.helplineTime}>
          <Typography component={"p"}>24/7 helpline</Typography>
        </Grid>
      </Grid>
      <Grid item>
        {token ? (
          <img src={PatientImage} alt="profile" />
        ) : (
          <Button
            btnTrigger={() => {
              dispatch(setModuleName(IPatientExistOrNotModule.LOGIN));
              dispatch(
                setAddPatient({
                  field: IAddPatient.MOBILE_CHECK,
                  value: true,
                })
              );
            }}
          >
            <FormattedMessage id="login" defaultMessage="Login" />
          </Button>
        )}
      </Grid>
      {moduleName === IPatientExistOrNotModule.LOGIN &&
        addPatient?.mobileCheck && <MobileOTPVerify />}
    </Grid>
  );
};

export default PatientTopNavigation;
