import { lazy } from "react";

const FindDoctor = lazy(() => import("../../views/doctor/FindDoctor"));
const Checkout = lazy(() => import("../../views/patient/Checkout"));
const DoctorProfile = lazy(() => import("../../views/common/DoctorProfile"));
const MedicalRecords = lazy(() => import("../../views/common/MedicalRecords"));
const Home = lazy(() => import("../../views/patient/Home"));
const SettingPreferences = lazy(
  () => import("../../views/patient/SettingPreferences")
);
const Faq = lazy(() => import("../../views/patient/FAQ"));
const HealthAssessment = lazy(
  () => import("../../views/patient/HealthAssessment")
);
const Orders = lazy(() => import("../../views/patient/Home/Orders"));
const Medicines = lazy(() => import("../../views/patient/Medicines"));
const PrescriptionPreview = lazy(
  () => import("../../components/Medicines/PrescriptionPreview")
);

const TestReports = () => {
  return <h1>test page</h1>;
};

const HealthTips = () => {
  return <h1>HealthTips</h1>;
};

const PatientRoutes = [
  { path: "/home", component: <Home /> },
  { path: "/find-doctor", component: <FindDoctor /> },
  { path: "/medicines", component: <Medicines /> },
  { path: "/prescription-preview", component: <PrescriptionPreview /> },
  { path: "/test-reports", component: <TestReports /> },
  { path: "/health-tips", component: <HealthTips /> },
  { path: "/checkout", component: <Checkout /> },
  { path: "/doctor-profile/:doctorId", component: <DoctorProfile /> },
  { path: "/medical-records", component: <MedicalRecords /> },
  { path: "/setting-preferences", component: <SettingPreferences /> },
  { path: "/faq", component: <Faq /> },
  { path: "/health-assessment", component: <HealthAssessment /> },
  { path: "/orders", component: <Orders /> },
];

export default PatientRoutes;
