import React from "react";
import { updateAdmitPatientApi } from "../apis/doctor/ipDashboard";

import { INotifyEnum, notify } from "../toaster";

import { fetchDoctorHospitalPatientListing } from "../../store/slices/doctor/ipDashboard";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

import { getTodayTomorrowDate } from "..";

import { IUpdateAdmitPatientRequest } from "../../models/ApiRequestResponse/doctor";

const useUpdateAdmitPatient = () => {
  const dispatch = useAppDispatch();
  const { selectedHospitalId } = useAppSelector((state) => state.ipDashboard);

  const updateAdmitPatientHandler = async (
    requedBody: IUpdateAdmitPatientRequest
  ) => {
    const reAdmitPatientResponse = await updateAdmitPatientApi(requedBody);
    if ("result" in reAdmitPatientResponse) {
      notify(INotifyEnum.SUCCESS, reAdmitPatientResponse.message);
      const { today, tomorrow } = getTodayTomorrowDate();
      dispatch(
        fetchDoctorHospitalPatientListing({
          hospitalId: selectedHospitalId,
          start_date: today,
          end_date: tomorrow,
        })
      );
      return;
    }
    notify(INotifyEnum.ERROR, reAdmitPatientResponse.message);
  };
  return { updateAdmitPatientHandler };
};

export default useUpdateAdmitPatient;
