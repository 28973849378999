import React from "react";
import { Dialog, Grid, Slide, styled } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";

import style from "./index.module.css";

const CustomDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    backgroundColor: "#f5f5f5",
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
  children: React.ReactNode;
  lineBackgroundColor?: string;
}
const FullScreenDialog: React.FC<IProps> = (props) => {
  const { children, lineBackgroundColor = "var(--gray-20)" } = props;

  return (
    <CustomDialog fullScreen open={true} TransitionComponent={Transition}>
      {children}
      <Grid
        item
        className={style.lineContainer}
        sx={{ backgroundColor: lineBackgroundColor }}
      >
        <Grid item className={style.lineBottom}></Grid>
      </Grid>
    </CustomDialog>
  );
};

export default FullScreenDialog;
