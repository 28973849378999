import { lazy } from "react";

const ScheduleListing = lazy(
  () => import("../../views/doctor/Schedule/Listing")
);
const AvailabilityListing = lazy(
  () => import("../../views/doctor/AvailabilitySettings/Listing")
);
const CreateEditAvailability = lazy(
  () => import("../../views/doctor/AvailabilitySettings/CreateEditAvailability")
);
const DoctorProfile = lazy(() => import("../../views/doctor/Profile"));
const DoctorProfileDetail = lazy(
  () => import("../../views/common/DoctorProfile")
);
const MedicalRecords = lazy(() => import("../../views/common/MedicalRecords"));
const CreatePrescription = lazy(
  () => import("../../views/doctor/Prescription/CreatePrescription")
);
const SearchAppointments = lazy(
  () => import("../../views/doctor/Schedule/SearchAppointments")
);
const IPDashboard = lazy(() => import("../../views/doctor/IPDashboard"));
const SearchIPDashboard = lazy(
  () => import("../../views/doctor/IPDashboard/SearchIPDashboard")
);
const AdmitPatient = lazy(
  () => import("../../views/doctor/IPDashboard/AdmitPatient")
);
const UniversalSearch = lazy(
  () => import("../../views/doctor/UniversalSearch")
);
const VideoCall = lazy(() => import("../../components/VideoCall"));

const Chat = () => {
  return <h1>Chat</h1>;
};

const DoctorRoutes = [
  { path: "/schedule", component: <ScheduleListing /> },
  { path: "/search-appointments", component: <SearchAppointments /> },
  { path: "/ip-dash", component: <IPDashboard /> },
  { path: "/search-ip", component: <SearchIPDashboard /> },
  { path: "/admit-patient", component: <AdmitPatient /> },
  { path: "/chats", component: <Chat /> },
  { path: "/profile", component: <DoctorProfile /> },
  { path: "/profile-detail", component: <DoctorProfileDetail /> },
  { path: "/availability-settings", component: <AvailabilityListing /> },
  { path: "/availability-create", component: <CreateEditAvailability /> },
  { path: "/patient-medical-records", component: <MedicalRecords /> },
  { path: "/create-prescription", component: <CreatePrescription /> },
  {
    path: "/search",
    component: <UniversalSearch />,
  },
  {
    path: "/join-room",
    component: <VideoCall />,
  },
];

export default DoctorRoutes;
