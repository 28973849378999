import React, { useState } from "react";
import { Grid } from "@mui/material";

import style from "./index.module.css";

import GetImages from "../../GetImages";

import { ITitleInputValuesListEnums } from "../../../models/doctor";

interface IRemoveItemFromList {
  identifier: ITitleInputValuesListEnums;
  itemIndex: number;
}

interface IProps {
  name: string;
  chipIndex: number;
  identifier: ITitleInputValuesListEnums;
  removeItemHandler: (
    Identifier: ITitleInputValuesListEnums,
    chipIndex: number,
    value?: string
  ) => void;
}
const Chip: React.FC<IProps> = (props) => {
  const { name, chipIndex, identifier, removeItemHandler } = props;

  const [removeItem, setRemoveItem] = useState<IRemoveItemFromList | null>(
    null
  );

  const removeChipHandler = () => {
    setRemoveItem({ identifier, itemIndex: chipIndex });
    if (removeItem?.identifier && removeItem?.itemIndex) {
      removeItemHandler(identifier, chipIndex);
    }
  };
  return (
    <Grid
      item
      sx={{
        background:
          removeItem?.itemIndex === chipIndex
            ? "var(--warm-neutral-50)"
            : "var(--neutral-100)",
      }}
      className={style.container}
    >
      <Grid item className={style.labelWithTrash}>
        <Grid item onClick={removeChipHandler}>
          {name}
        </Grid>
        {removeItem?.identifier === identifier &&
          removeItem?.itemIndex === chipIndex && (
            <Grid
              item
              className={style.removeItem}
              onClick={() => removeItemHandler(identifier, chipIndex, name)}
            >
              <GetImages name="TrashSmallIcon" width="16" height="16" />
            </Grid>
          )}
      </Grid>
    </Grid>
  );
};

export default Chip;
