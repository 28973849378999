import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";

import Modal from "../Modal";
import ProfileStatusBadge from "../ProfileStatusBadge";
import Button from "../Button";
import { InputField } from "../InputField";
import GetImages from "../GetImages";
import Dialog from "../Dialog";

import style from "./index.module.css";

import PersonImage from "../../assets/p1.png";

import { IDoctorProfileStatus } from "../../models/doctor";
import { IButtonVariant } from "../../models/button";

import useIsMobile from "../../utils/hooks/useIsMobile";

interface IProps {
  isOpen: boolean;
  closeHandler: () => void;
  setSelectedStar: (selectedStar: number) => void;
  reviewMessage: string;
  setReviewMessage: (message: string) => void;
  submitHandler: () => void;
}
const DoctorFeedBack: React.FC<IProps> = (props) => {
  const [starIcons, setStarIcons] = useState(
    Array(5).fill({
      isSelected: false,
      icon: <GetImages name="GreyedStarIcon" width="40" height="40" />,
    })
  );
  const [starSelected, setStarSelected] = useState<boolean>(false);

  const {
    isOpen,
    closeHandler,
    setSelectedStar,
    reviewMessage,
    setReviewMessage,
    submitHandler,
  } = props;

  const { isMobile } = useIsMobile();

  const ratingSelectionHandler = (selectedStarIndex: number) => {
    const star = [];
    for (let i = 0; i < selectedStarIndex + 1; i++) {
      star.push({
        isSelected: true,
        icon: <GetImages name="YellowStarIcon" width="40" height="40" />,
      });
    }
    if (star.length === 5) {
      setStarIcons(star);
    } else {
      const totalStar = 5;
      const diff = totalStar - star.length;
      setStarIcons([
        ...star,
        ...Array(diff).fill({
          isSelected: false,
          icon: <GetImages name="GreyedStarIcon" width="40" height="40" />,
        }),
      ]);
    }
  };

  const doctorFeedbackScreen = () => (
    <Grid item className={style.container}>
      <Typography component={"p"}>Your Consultation with</Typography>
      <Grid item className={style.doctorImageContainer}>
        <img src={PersonImage} alt="doctorPhoto" width={80} height={80} />
        <Grid item className={style.doctorProfileStatus}>
          <ProfileStatusBadge status={IDoctorProfileStatus.VERIFIED} />
        </Grid>
      </Grid>
      <Grid item className={style.doctorDetailContainer}>
        <Typography component={"h4"}>Dr. Hardik Rajyaguru</Typography>
        <Typography component={"h6"}>
          16 yrs • Neurosurgeon • MBBS, M.Ch
        </Typography>
        <Typography component={"p"}>Delta Hospital • Indiranagar</Typography>
      </Grid>
      <Grid item className={style.starIconContainer}>
        <Grid item className={style.starIcon}>
          {starIcons.map((item, index) => (
            <Grid
              item
              key={index}
              onClick={() => ratingSelectionHandler(index)}
            >
              {item.icon}
            </Grid>
          ))}
        </Grid>
      </Grid>
      {starSelected && (
        <Grid item className={style.reviewField}>
          <InputField
            type="text"
            value={reviewMessage}
            onChange={(event) => setReviewMessage(event.target.value)}
            label="Add Review"
            variant="outlined"
            placeholder="Add Review"
            fullWidth
          />
        </Grid>
      )}
      <Grid item>
        <Button
          disabled={!starSelected}
          variant={IButtonVariant.ORANGE}
          btnTrigger={submitHandler}
        >
          Submit
        </Button>
      </Grid>
    </Grid>
  );

  useEffect(() => {
    const selectedStarCount = starIcons.filter(
      (starIcon) => starIcon.isSelected
    ).length;
    setStarSelected(selectedStarCount > 0);
    setSelectedStar(selectedStarCount);
  }, [starIcons]);
  return (
    <>
      {isMobile ? (
        <Modal isOpen={isOpen} closeHandler={closeHandler}>
          {doctorFeedbackScreen()}
        </Modal>
      ) : (
        <Dialog isOpen={isOpen} closeHandler={closeHandler}>
          {doctorFeedbackScreen()}
        </Dialog>
      )}
    </>
  );
};

export default DoctorFeedBack;
