import { Grid } from "@mui/material";
import React, { useState } from "react";

import Title from "../../Title";
import Button from "../../Button";
import MedicineTestSuggestions from "../MedicineTestSuggestions";
import GetImages from "../../GetImages";
import MedicineTestListRemoveManagerWeb from "../MedicineTestListRemoveManagerWeb";

import { IButtonVariant } from "../../../models/button";
import {
  IMedicineTestSuggestionData,
  ITitleInputValuesListEnums,
} from "../../../models/doctor";

import style from "./index.module.css";
import { useAppDispatch } from "../../../store/hooks";
import { setPrescriptionTemplate } from "../../../store/slices/doctor/prescription";

const medicineTestInputSuggestionConfig = [
  {
    title: "Medicines",
    type: ITitleInputValuesListEnums.MEDICINE,
    buttonInput: {
      label: "Medicine",
      detail: { icon: "MedicineSmallIcon", label: "Add Medicine" },
    },
  },
  {
    title: "Tests",
    type: ITitleInputValuesListEnums.TEST,
    buttonInput: {
      label: "Test",
      detail: { icon: "TestSmallIcon", label: "Add Test" },
    },
  },
];

const MedicineTestInputSuggestion: React.FC = () => {
  const [medicineTestInputShowType, setMedicineTestInputShowType] =
    useState<ITitleInputValuesListEnums | null>(null);

  const dispatch = useAppDispatch();

  const getMedicineTestDetailHandler = (
    detail: IMedicineTestSuggestionData,
    identifier: ITitleInputValuesListEnums
  ) => {
    const isMedicine = identifier === ITitleInputValuesListEnums.MEDICINE;
    const { medicineId, testId, name } = detail;
    dispatch(
      setPrescriptionTemplate({
        identifier,
        value: isMedicine ? [{ name, medicineId }] : [{ name, testId }],
      })
    );
    setMedicineTestInputShowType(null);
  };

  const medicineTestInputShow = (type: ITitleInputValuesListEnums) => {
    setMedicineTestInputShowType(type);
  };

  return (
    <Grid item>
      {medicineTestInputSuggestionConfig.map((medicineTestDetail, index) => (
        <Grid item className={style.medicineTestContainer} key={index}>
          <Grid item textAlign={"left"} className={style.title}>
            <Title>{medicineTestDetail.title}</Title>
          </Grid>
          <MedicineTestListRemoveManagerWeb type={medicineTestDetail.type} />
          {medicineTestInputShowType === medicineTestDetail?.type && (
            <Grid item className={style.medicineTestInputSuggestionContainer}>
              <MedicineTestSuggestions
                label={medicineTestDetail?.buttonInput.detail.label}
                type={medicineTestDetail?.type}
                getMedicineTestDetailEnterEventHandler={
                  getMedicineTestDetailHandler
                }
              />
            </Grid>
          )}
          <Grid item className={style.medicineTestBtnContainer}>
            <Button
              variant={IButtonVariant.WHITE}
              btnTrigger={() => medicineTestInputShow(medicineTestDetail?.type)}
            >
              <GetImages
                name={medicineTestDetail?.buttonInput?.detail?.icon}
                width="26"
                height="20"
              />
              {medicineTestDetail?.buttonInput?.detail?.label}
            </Button>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default MedicineTestInputSuggestion;
