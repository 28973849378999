import {
  IAllOrdersResponse,
  IFAQResponse,
  IFAQTagResponse,
  IHomeResponse,
} from "../../../models/ApiRequestResponse/patient";
import { GBSCError } from "../../../models/GBSCError";

import { get } from "../../TypedApi";

import {
  APIEndpoint,
  IAPIStatusCode,
  IAPIStatusType,
  messages,
} from "../APIEndpointConfig";

export const getHomeDetailApi = async (
  userId: string
): Promise<IHomeResponse | GBSCError> => {
  try {
    const response = await get<IHomeResponse>(
      `${APIEndpoint.patient.home}${userId}`
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }
  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const getFAQTagListApi = async (): Promise<
  IFAQTagResponse | GBSCError
> => {
  try {
    const response = await get<IFAQTagResponse>(APIEndpoint.patient.faqTags);
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }
  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const getFAQListApi = async (
  tag: string
): Promise<IFAQResponse | GBSCError> => {
  try {
    const response = await get<IFAQResponse>(
      `${APIEndpoint.patient.faq}${tag}`
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }
  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const getAllOrdersApi = async (
  orderType: string
): Promise<IAllOrdersResponse | GBSCError> => {
  try {
    const response = await get<IAllOrdersResponse>(
      `${APIEndpoint.patient.allOrders}${orderType}`
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }
  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};
