import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { GBSCError } from "../../../models/GBSCError";
import {
  IHomeDetail,
  IFAQDetail,
  IFAQTagResponse,
  IFAQTag,
  IAllOrdersDetail,
} from "../../../models/ApiRequestResponse/patient";

import {
  getAllOrdersApi,
  getFAQListApi,
  getFAQTagListApi,
  getHomeDetailApi,
} from "../../../utils/apis/patient/home";

interface ITagName {
  tag: string;
}

export const fetchHomeModules = createAsyncThunk(
  "patient/home",
  async ({ userId }: { userId: string }) => {
    const response = await getHomeDetailApi(userId);
    return response;
  }
);

export const fetchFAQTagList = createAsyncThunk<IFAQTagResponse | GBSCError>(
  "patient/faq-tags",
  async () => {
    const response = await getFAQTagListApi();
    return response;
  }
);

export const fetchFAQList = createAsyncThunk(
  "patient/faq",
  async ({ tag }: ITagName) => {
    const response = await getFAQListApi(tag);
    return response;
  }
);

export const fetchAllOrdersList = createAsyncThunk(
  "patient/all-orders",
  async ({ orderType }: { orderType: string }) => {
    const response = await getAllOrdersApi(orderType);
    return response;
  }
);

const fetchHomeModuleSlice = createSlice({
  name: "fetchHomeModule",
  initialState: {
    detail: {} as IHomeDetail,
    faq: [] as IFAQDetail[],
    faqTags: [] as IFAQTag[],
    allOrders: [] as IAllOrdersDetail[],
    isHomeLoading: true as boolean,
    isFAQLoading: true as boolean,
    isFAQTagLoading: true as boolean,
    isAllOrdersLoading: true as boolean,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Home
      .addCase(fetchHomeModules.pending, (state) => {
        state.isHomeLoading = true;
      })
      .addCase(fetchHomeModules.fulfilled, (state, action) => {
        state.isHomeLoading = false;
        if ("result" in action.payload) {
          state.detail = action.payload.result;
        }
      })
      .addCase(fetchHomeModules.rejected, (state) => {
        state.isHomeLoading = false;
      })
      // FAQ Tags
      .addCase(fetchFAQTagList.pending, (state) => {
        state.isFAQTagLoading = true;
      })
      .addCase(fetchFAQTagList.fulfilled, (state, action) => {
        state.isFAQTagLoading = false;
        if ("result" in action.payload) {
          state.faqTags = action.payload.result;
        }
      })
      .addCase(fetchFAQTagList.rejected, (state) => {
        state.isFAQTagLoading = false;
      })
      // FAQ
      .addCase(fetchFAQList.pending, (state) => {
        state.isFAQLoading = true;
      })
      .addCase(fetchFAQList.fulfilled, (state, action) => {
        state.isFAQLoading = false;
        if ("result" in action.payload) {
          state.faq = action.payload.result;
        }
      })
      .addCase(fetchFAQList.rejected, (state) => {
        state.isFAQLoading = false;
      })
      // All orders
      .addCase(fetchAllOrdersList.pending, (state) => {
        state.isAllOrdersLoading = true;
      })
      .addCase(fetchAllOrdersList.fulfilled, (state, action) => {
        state.isAllOrdersLoading = false;
        if ("result" in action.payload) {
          state.allOrders = action.payload.result;
        }
      })
      .addCase(fetchAllOrdersList.rejected, (state) => {
        state.isAllOrdersLoading = false;
      });
  },
});

export default fetchHomeModuleSlice.reducer;
